@import "../../../styles/sass/NewThemeVariables.scss";

.app-card-styles-RAC {
  height: 100px;
  width: 245px;
  border-radius: 3px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: 0.4s;
  &.dark-RAC {
    background-color: $darkThemelightCharcoal !important;
    color: $lightThemeWhiteBg;
    border: 1px solid $darkThemeDarkCharcoal;
  }

  &.light-RAC {
    background-color: $lightThemeWhiteBg !important;
    color: $darkThemelightCharcoal;
    border: 1px solid #dbdbdb;
  }

  &:hover {
    border: 1px solid #f7007f;
    box-shadow: 0px 4px 8px #0000005a;
    cursor: pointer;
  }
}

.icon-name-description-container {
  display: flex;
  align-items: center;
  width: 100%;
  // padding-top: 10px;
  // height: 70px;
  height: 100%;
}

.icon-name-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  border-right: 1px solid #00000029;
}

.application-name-RAC {
  margin: 0px;
  text-align: center;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  padding: 0 6px;
  -webkit-box-orient: vertical;
}
.application-name-RAC-hover {
  margin: 0px;
  color: #f7007f;
  text-align: center;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  padding: 0 6px;
  -webkit-box-orient: vertical;
}

.application-description-container-RAC {
  height: 100%;
  width: 60%;
}

.application-description-RAC {
  margin: 0px;
  font-size: 10px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  padding: 0 10px;
  padding-left: 23px;
  -webkit-box-orient: vertical;
  &.dark-RAC {
    color: $lightThemeWhiteBg !important;
    &:hover {
      color: $lightThemeWhiteBg !important;
    }
  }

  &.light-RAC {
    color: $darkThemelightCharcoal !important;
    &:hover {
      color: $darkThemelightCharcoal !important;
    }
  }
}

.details-button-RAC {
  padding-bottom: 2px;
  height: 18px;
  font-size: 11px;
  color: #f7007f;
  background-color: transparent;
  font-weight: 400;
  position: absolute;
  bottom: 12px;
  left: 24px;
  padding: 0px;
  text-decoration: underline;
}
.details-button-RAC-hover {
  padding-bottom: 2px;
  height: 18px;
  font-size: 11px;
  background-color: #f7007f;
  font-weight: 400;
  position: absolute;
  bottom: 12px;
  border-radius: 50px;
  left: 24px;
  color: #fff;
}

.applications-row-grid-style-RAC {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(245px, 0.1fr)) !important;
  grid-gap: 8px !important;
  padding: 10px 0px;
  border-radius: 10px;
  &.dark-RAC {
    // background-color: $darkThemelightCharcoal !important;
  }

  &.light-RAC {
    // background-color: #f9e6ea !important;
  }
}

.error-styles-RAC {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;

  &.dark-RAC {
    background-color: $darkThemelightCharcoal !important;
  }

  &.light-RAC {
    background-color: $lightThemeWhiteBg !important;
  }
}

.app-status-home-icon-RAC {
  position: absolute !important;
  right: 5px !important;
  top: 3px !important;
  font-size: 16px !important;
  color: #fc007f !important;
}

.div-box-recommended {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-top: 8px;
  width: 100%;
}
