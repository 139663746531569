.user-info-name {
    border-bottom: 1px solid#EAEAEA;
    padding: 10px 12px;
    font-size: 14px;
    // cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info-text {
        text-transform: capitalize;
        margin: 0px;
    }
    .user-info-sub-text {
        margin: 0px;
    }
}

.user-info-sign-out {
    padding: 10px 12px;
    font-size: 14px;
    .user-info-sign-out-text {
        margin: 0px;
        cursor: pointer;
    }
}

.rotated-toggle-theme {
    font-size: 30px !important;
}


.profile-image-container {
    cursor: pointer;
    display: flex;
    .alphabet-picture {
        width: 32px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        // margin-right: 20px;
        border: 2px solid #fecce5;
        .name-initials {
            font-size: 15px;
            font-weight: 600;
            color: white;
        }
    }
}

.user-info-profile {
    display: flex;
    align-items: center;
}
.user-info-hover {
    &:hover {
        background-color: #eaeaea;
    }
}

.dark {
    &-user-info-hover {
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.08);
        }
    }
}

.light {
    &-user-info-hover {
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }
}
