@import "../../../styles/sass/NewThemeVariables.scss";

.rb-image-content-container {
    display: flex;
    align-items: center;
    margin: 20px 60px 0px 60px;
    .rb-text-content {
        margin-left: 20px;
        .rb-text-heading {
            font-size: 30px;
            color: $secondaryColor;
            font-weight: 600;
            font-family: $fontFamily;
        }
        .rb-text {
            font-size: 12px;
            font-family: $fontFamily;
            white-space: nowrap;
            &.dark {
                color: $lightThemeWhiteBg;
            }
            &.light {
                color: $primaryColor;
            }
        }
    }
}
