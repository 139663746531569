@import "../../../styles/sass/NewThemeVariables.scss";

aside {
  // width: $sidenavWidth;
  z-index: 1;

  .menu-div {
    display: flex;
    align-items: center;
    padding: 10px 20px 5px 20px;
    cursor: pointer;
    .nav-text-menu {
      margin-left: 12px;
      color: $primaryColor;
    }
  }

  // .covid-faq-div {
  //   display: flex;
  //   align-items: center;
  //   padding: $sidenavItemPadding;
  //   cursor: pointer;
  //   color: $primaryColor;
  //   position: fixed;
  //   bottom: 30px;

  //   .nav-icon {
  //     width: $sidenavIconSize;
  //     font-size: $sidenavIconSize;
  //     margin-right: $sidenavIconMarginRight;
  //   }

  //   .nav-text {
  //     margin: 0px;
  //     display: flex;
  //     font-size: $sidenavTextSize;
  //     justify-content: left;
  //     align-items: center;
  //   }
  // }

  nav {
    padding-top: $paddingAroundSingle;
    .nav-item {
      display: flex;
      align-items: center;
      padding: $sidenavItemPadding;
      cursor: pointer;

      &.special {
        border-radius: 3px;
        font-weight: 600;
        &.active {
          display: flex;
          align-items: center;
          margin: none;
          justify-content: flex-start;
          width: inherit;
          padding: $sidenavItemPadding;
          border-radius: 3px;
        }
      }

      .nav-icon {
        width: $sidenavIconSize;
        font-size: $sidenavIconSize;
        margin-right: $sidenavIconMarginRight;
      }

      .nav-text {
        margin: 0px;
        display: flex;
        font-size: $sidenavTextSize;
        justify-content: left;
        align-items: center;
        transition: font-size $transition;

        &.hidden {
          font-size: 0px;
          opacity: 0;
        }

        &.visible {
          font-size: 13px;
          opacity: 1;
        }
      }
    }

    .nav-item-menu {
      display: flex;
      align-items: center;
      padding: $sidenavItemPadding;
      margin: 5px 10px;
      cursor: pointer;
      .nav-icon-menu {
        width: $sidenavIconSize;
        font-size: $sidenavIconSize;
        margin-right: $sidenavIconMarginRight;
        color: $primaryColor;
      }
      .nav-text-menu {
        display: flex;
        justify-content: left;
        align-items: center;
        //font-weight: 600;
        font-size: 15px;
        height: 25px;
        margin: 0px;
        color: $primaryColor;
        transition: all $transition;

        &.hidden {
          font-size: 0px;
          opacity: 0;
        }

        &.visible {
          font-size: 13px;
          opacity: 1;
        }
      }
    }
  }
}

aside.sidenav-expanded {
  width: $sidenavWidthExpanded;
}

aside.sidenav-collapsed {
  width: $sidenavWidthCollapsed;
}

aside.light {
  box-shadow: $sidenavBoxShadow;
  background-color: $sidenavBGColorLight;
  transition: width $transition;

  // .covid-faq-div {
  //   display: flex;
  //   align-items: center;
  //   padding: $sidenavItemPadding;
  //   cursor: pointer;
  //   color: $primaryColor;
  //   position: fixed;
  //   bottom: 25px;
  //   width: 235px;
  //   box-sizing: border-box;
  //   background: $sidenavBGColorLight;

  //   .nav-icon {
  //     width: $sidenavIconSize;
  //     font-size: $sidenavIconSize;
  //     margin-right: $sidenavIconMarginRight;
  //     color: $primaryColor;
  //   }

  //   .nav-text {
  //     margin: 0px;
  //     display: flex;
  //     font-size: $sidenavTextSize;
  //     justify-content: left;
  //     align-items: center;
  //   }
  // }

  nav {
    // border-bottom: 1px solid $sidenavBorderColor;

    .nav-item {
      color: $primaryColor;
      margin: 5px 10px;

      &.special {
        background-color: $sidenavActiveFontColor;
        color: white;
        margin: 5px 10px;

        &:hover {
          background: rgba(235, 54, 127, 0.1);
          color: $sidenavActiveFontColor;
        }

        &.active {
          &:hover {
            background: rgba(235, 54, 127, 0.1);
            color: $sidenavActiveFontColor;
          }
        }
      }

      &.active,
      &:hover {
        color: $sidenavActiveFontColor;
        background-color: rgba(235, 54, 127, 0.1);
        border-radius: 3px;
      }
    }
  }
}

.activereport-comment-label {
  margin: 0px;
  padding-left: 20px;
  margin-bottom: 18px;
  font-weight: 600;
  position: sticky;
  font-size: 14px;
  top: 52px;
}

.no-comments {
  margin: 0px;
  padding-left: 20px;
  margin-bottom: 18px;
  font-weight: 600;
  position: sticky;
  font-size: 14px;
  top: 52px;
  display: flex;
  justify-content: center;
  &.dark-no-comment-section {
    background-color: $darkThemeDarkCharcoal;
    color: $lightThemeWhiteBg;
  }
  &.light-no-comment-section {
    background-color: $lightThemeWhiteBg;
    color: $primaryColor;
  }
}

.comment-text-cross-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  &.dark-comment-section {
    background-color: $darkThemeDarkCharcoal;
    color: $lightThemeWhiteBg;
  }
  &.light-comment-section {
    background-color: $lightThemeWhiteBg;
    color: $primaryColor;
  }
  .drawer-cancel-icon {
    color: $secondaryColor;
    display: flex !important;
    justify-content: flex-end;
    padding: 5px 20px 0px !important;
  }

  .comment-header-text {
    font-weight: 600;
    padding: 0px 37px;
    text-transform: capitalize;
    font-size: 16px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

.comments-container {
  height: calc(100vh - 160px);
  overflow-y: auto;
  .comment-list-item {
    padding: 0px 20px;
    margin-bottom: 15px;
    &.dark-list {
      color: white;
    }
    &.light-list {
      color: $primaryColor;
    }
    .commented_by_text {
      margin: 0px;
      font-size: 15px;
      font-weight: 900;
    }
    .list-primary-text {
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      margin-top: 5px;
    }
    .list-secondary-text {
      margin-top: 5px;
      font-size: 13px;
    }

    .comment-secondary-text {
      margin: 0px;
      font-size: 13px;
      width: 100%;
      color: #677a89;
    }

    .comments-info {
      padding-left: 42px;
    }

    .msg-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.8em;
      margin-top: 8px;
      .sub-msg-info {
        // margin-top: 12px;
        display: flex;
        font-weight: 900;
        align-items: center;
        justify-content: space-between;
        .comment-text {
          padding-right: 15px;
          display: flex;
          p {
            padding-right: 30px;
          }
        }
        .comment-action {
          display: flex;
          justify-content: flex-end;
        }
      }
      .fav-icon {
        color: $secondaryColor;
        font-size: 20px;
        padding-left: 20px;
      }
      .delete-icon {
        font-size: 20px;
        padding-left: 20px;
      }
    }
  }
}

.list-item {
  padding: 0px 20px;
  color: $primaryColor;
  margin-bottom: 15px;
  .commented_by_text {
    margin: 0px;
    font-size: 15px;
    font-weight: 900;
  }
  .list-primary-text {
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    margin-top: 5px;
  }
  .list-secondary-text {
    margin-top: 5px;
    font-size: 13px;
  }

  .comment-value-text {
    margin: 0px;
    font-size: 13px;
    width: 90%;
  }

  .comments-info {
    padding-left: 42px;
  }

  .msg-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
    margin-top: 8px;
    .sub-msg-info {
      // margin-top: 12px;
      display: flex;
      font-weight: 900;
      align-items: center;
      justify-content: space-between;
      .comment-text {
        padding-right: 15px;
        display: flex;
        p {
          padding-right: 30px;
        }
      }
      .comment-action {
        display: flex;
        justify-content: flex-end;
      }
    }
    .fav-icon {
      color: $secondaryColor;
      font-size: 20px;
      padding-left: 20px;
    }
    .delete-icon {
      color: $primaryColor;
      font-size: 20px;
      padding-left: 20px;
    }
  }
}
