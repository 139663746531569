.profile {
    &-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #8d9ca6;
        font-weight: 600;
        span {
            color: #fc007f;
            margin: 0 10px;
        }
    }
    &-btn {
        height: auto;
        display: flex;
        align-items: center;
        font-size: 22px;
        &-approve {
            background: #119b38;
            border-radius: 3px;
            color: #fff;
        }
        &-cancel {
            background: #df475a;
            border-radius: 3px;
            color: #fff;
        }
    }
    &-status {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        &-approve {
            color: #119b38;
        }
        &-reject {
            color: #df475a;
        }
    }
    &-show-icon {
        & svg {
            opacity: 1;
        }
    }
    &-hide-icon {
        & svg {
            opacity: 0;
        }
    }
}
.dark {
    &-profile {
        &-text {
            display: flex !important;
            align-items: center;
            justify-content: center;
            input {
                color: #fff !important;
            }
        }
    }
}
.light {
    &-profile {
        &-text {
            display: flex !important;
            align-items: center;
            justify-content: center;
            input {
                color: #282828 !important;
            }
        }
    }
}
