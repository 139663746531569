@import "../../../styles/sass/NewThemeVariables.scss";
@import "../../font.css";

.new-theme-hover-class {
  color: $secondaryColor;
}

.lock-hover {
  &-dark {
    color: #fff;
  }
  &-light {
    color: #000;
  }
}

.info-hover {
  color: $newPrimaryColor;
}

.globe-hover {
  color: green;
}

.dark-card-background {
  background: #282828;
}
.light-card-background {
  background: #fff;
}

.application-category-name {
  text-transform: uppercase;
  margin-left: 50px;
  font-size: 9px;
  color: #979797;
}

.large-card-container {
  font-weight: 400;
  width: 225px;
  height: 210px;
  border-radius: 3px;
  border: 1px solid #0000002e;
  //   box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 3px 10px 0px #0000004a;
    transition: 0.5s;
    border: 1px solid $secondaryColor;
  }

  .new-icon-style {
    position: absolute;
    right: 0px;
    bottom: 15px;
    height: 15px;
  }

  .application-upper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0000002e;
    padding: 8px;
    height: 17px;

    .application-access-type {
      font-family: $fontFamily;
      text-transform: uppercase;
      color: #979797;
    }

    .icons-content-container {
      width: 35px;
      justify-content: space-between;
      display: flex;
      color: #b0b0b0;
      cursor: pointer;
      .new-app-status-icon {
        position: absolute !important;
        right: 10px !important;
        top: 10px !important;
        font-size: 15px !important;
      }
      .status-schedule {
        color: #ed8a2c !important;
      }
      .status-approved {
        color: #55b82a !important;
      }
      .status-rejected {
        color: #df635d !important;
      }
    }
  }
  .text-content-container {
    padding: 15px 25px;
    .app-name-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin: 10px 0px;
      letter-spacing: 0.1px;
      text-align: left;
      font-family: $fontFamily;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .app-description-text {
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
      line-height: 18px;
      letter-spacing: 0.1px;
      text-align: left;
      font-family: $fontFamily;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .app-icon {
    position: absolute;
    left: 6px;
    top: 6px;
    padding: 6px;
    border-radius: 32px;
    border: 1px solid #0000002e;
  }
  .dark-icon {
    background: #282828;
    color: #ffffff;
  }
  .light-icon {
    color: $secondaryColor;
    background: #fff;
  }
}

.small-card-container {
  font-weight: 400;
  width: 225px;
  height: 140px;
  border-radius: 3px;
  border: 1px solid #0000002e;
  cursor: pointer;
  //   box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  font-size: 13px;
  &:hover {
    box-shadow: 2px 3px 10px 0px #0000004a;
    transition: 0.5s;
    border: 1px solid $secondaryColor;
  }

  .new-icon-style {
    position: absolute;
    right: 0px;
    bottom: 15px;
    height: 15px;
  }

  .application-upper-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0000002e;
    padding: 8px;
    height: 17px;

    .application-access-type {
      font-family: $fontFamily;
      text-transform: uppercase;
      color: #979797;
    }

    .icons-content-container {
      width: 35px;
      justify-content: space-between;
      display: flex;
      color: #b0b0b0;
      cursor: pointer;
      .new-app-status-icon {
        position: absolute !important;
        right: 10px !important;
        top: 10px !important;
        font-size: 15px !important;
      }
      .status-schedule {
        color: #ed8a2c !important;
      }
      .status-approved {
        color: #55b82a !important;
      }
      .status-rejected {
        color: #df635d !important;
      }
    }
  }
  .text-content-container {
    padding: 15px 25px;
    .app-name-text {
      font-size: 14px;
      margin: 10px 0px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: left;
      font-family: $fontFamily;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .app-icon {
    position: absolute;
    left: 6px;
    top: 6px;
    padding: 6px;
    border-radius: 32px;
    border: 1px solid #0000002e;
  }
  .dark-icon {
    background: #282828;
    color: #ffffff;
  }
  .light-icon {
    color: $secondaryColor;
    background: #fff;
  }
}

.new-theme-home-icon {
  font-size: 15px !important;
}
