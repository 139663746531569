@import "../../../styles/sass/NewThemeVariables.scss";

.applications-container {
  font-family: "Energy" !important;
  .aide-text {
    width: 28%;
    align-items: center;
    justify-content: space-between;
    float: left;
    height: 100%;
    margin-right: 2%;
    padding: 9%;
    color: $primaryColor;
    line-height: 160%;
  }

  .main-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .img-container {
    display: flex !important;
    justify-content: center !important;
  }

  .aide-root {
    // padding: 30px;
    padding: 1.5% 1.5%;
    box-sizing: border-box;
    // padding-top: 9%;

    .applications-container {
      margin-bottom: 30px;
      .applications-title {
        font-weight: $fontWeightBold;
        color: $primaryColor;
      }
      .applications-row {
        margin: 0px 0px 35px 0px;
        border-radius: 10px;

        .applications-row-header {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
          margin-bottom: 10px;
        }

        .applications-row-view {
          cursor: pointer;
          font-size: 13px;
          color: #282828;
          font-weight: $fontWeightNormal;
          text-decoration: underline;
          text-decoration-line: 2px;
          text-underline-offset: 2px;
        }

        .applications-row-title {
          margin: 0px 0px 8px 0px;
          font-size: 18px;
          color: $secondaryColor;
          font-weight: $fontWeightBold;
          font-style: bolder;
        }

        .applications-row-grid {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax($appSideHomePage, 0.1fr)
          );
          grid-gap: $appRowGridGapHomePage;

          .applications-card-container-faq {
            width: 120px;
            color: white;
            position: relative;
            display: flex;
            cursor: pointer;
            transition: all 0.1s linear;
            .application {
              width: $appSideHomePage;
              height: $appSideHomePage;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              // border: 2px solid $inputEleBlackBorderColor;
              // margin-right: 2%;
              box-sizing: border-box;
              &.dark {
                background-color: $darkThemelightCharcoal !important;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                color: $lightThemeWhiteBg;
              }

              &.light {
                background-color: $lightThemeWhiteBg !important;
                box-shadow: 0px 4px 10px rgba(221, 221, 221, 0.25);
                color: $primaryColor;
              }

              &:hover {
                transform: scale(1.05);
                color: $secondaryColor;
                border: 1px solid #fd5bb2;
              }

              .application-icon {
                font-size: $appIconSizeLarge;
              }

              .application-title {
                margin: 0px;
                text-align: center;
                font-size: small;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                box-sizing: border-box;
                text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
              }

              .application-card-item {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}

.primary-text {
  font-size: 23px;
  font-weight: $fontWeightBold;
}

.secondary-text {
  font-size: $primaryFontSizeL;
  font-weight: $fontWeightLight;
}


.faq-data-quote {
  font-family: Energy;
  font-size: 15px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  align-items: center;
  margin: 15px 0px 25px;
  &-container {
    border-bottom: 1px solid #8f8f8f;
    min-width: 834px;
    max-width: 1144px;
    width: 83%;
    padding-bottom: 13px;
    position: relative;
    margin: 0px auto;
    text-align: center;
    &-dark{
        border-color: #8f8f8f;
    }
}
}


.faq-container1{
  display: flex;
  justify-content: space-between;
  min-width: 834px;
  max-width: 1144px;
  width: 83%;
  align-items: center;
  color:#4E4E4E;
  font-family: Energy;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  height: 60px;
  &-btn{
    display:flex;
    background-color: transparent;
    border:1px solid #8f8f8f ;
    color:#000;
    align-items: center;
    gap:10px;
    padding: 1px 7px 1px 15px;
    font-weight:100;
    &-dark{
      border-color: #8f8f8f;
    }
  }
  &-btn:hover{
      background-color: #0058AA;
      color:#fff;
      border:none;
  }
  &-btn:hover .doc-img-light{
    filter: brightness(0) invert(1)
}
  
}

.faq-container2{
  display: flex;
  flex-direction: column;
  min-width: 834px;
  max-width: 1144px;
  width: 83%;
  padding: 0px 2%;
}

.faq-container-dark{
  color:#fff
}


