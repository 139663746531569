@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
@import "./styles/sass/NewThemeVariables.scss";

body {
  margin: 0;
  font-family: "Energy" !important;
  overflow: hidden;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
  outline: -moz-focus-ring-color auto 0px !important;
}

.disabled-style {
  text-decoration: none !important;
}

.error {
  color: $errorColor;
  //margin: 5px !important;
}

button {
  font-family: "Energy";
  font-weight: 200;
  font-size: 12px;
  background-color: $primaryHeaderColor;
  border: 1px solid transparent;
  border-radius: 3px;
  height: 30px;
  color: white;
  cursor: pointer;
}

.material-icons-outlined,
.material-icons {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 10px;
  // background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*placeholder*/
::-webkit-input-placeholder {
  font-weight: 300 !important;
}
