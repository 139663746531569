@import "../../../styles/sass/NewThemeVariables.scss";

.new-theme-applications-row-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 0.1fr));
  grid-gap: 15px;
}

.applications-container {
  font-family: "Energy" !important;
  .aide-text {
    width: 28%;
    align-items: center;
    justify-content: space-between;
    float: left;
    height: 100%;
    margin-right: 2%;
    padding: 9%;
    color: $primaryColor;
    line-height: 160%;
  }

  .main-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .img-container {
    display: flex !important;
    justify-content: center !important;
  }

  .aide-root {
    // padding: 30px;
    padding: 1.5% 1.5%;
    box-sizing: border-box;
    // padding-top: 9%;

    .applications-container {
      margin-bottom: 30px;
      .applications-title {
        font-weight: $fontWeightBold;
        color: $primaryColor;
      }
      .applications-row {
        margin: 0px 0px 35px 0px;
        border-radius: 10px;

        .applications-row-header {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
          margin-bottom: 10px;
        }

        .applications-row-view {
          cursor: pointer;
          font-size: 13px;
          color: #282828;
          font-weight: $fontWeightNormal;
          text-decoration: underline;
          text-decoration-line: 2px;
          text-underline-offset: 2px;
        }

        .applications-row-title {
          margin: 0px 0px 8px 0px;
          font-size: 18px;
          color: $secondaryColor;
          font-weight: $fontWeightBold;
          font-style: bolder;
        }

        .applications-row-grid {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax($appSideHomePage, 0.1fr)
          );
          grid-gap: $appRowGridGapHomePage;

          .applications-card-container {
            width: 100px;
            color: white;
            position: relative;
            display: flex;
            cursor: pointer;
            transition: all 0.1s linear;
            .application {
              width: $appSideHomePage;
              height: $appSideHomePage;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              // border: 2px solid $inputEleBlackBorderColor;
              // background-color: $appBGColor;
              color: $primaryColor;
              // margin-right: 2%;
              box-sizing: border-box;
              box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.5);

              &:hover {
                transform: scale(1.05);
              }

              &:hover {
                background-color: $primaryColor !important;
                color: $appBGColor !important;
                border-radius: 3px;
              }

              .application-icon {
                font-size: $appIconSizeLarge;
              }

              .application-title {
                margin: 0px;
                text-align: center;
                font-size: small;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                box-sizing: border-box;
                text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
              }

              .application-card-item {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}

.primary-text {
  font-size: 23px;
  font-weight: $fontWeightBold;
}

.secondary-text {
  font-size: $primaryFontSizeL;
  font-weight: $fontWeightLight;
}

.applications-card-container-movable {
  width: 120px;
  color: white;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: all 0.1s linear;
  border-radius: 5px;
  margin: 0px 10px 10px 0px;
  .application-1 {
    width: $appSideHomePage;
    height: $appSideHomePage;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    // border: 2px solid $inputEleBlackBorderColor;
    background-color: #ffffff;
    color: $primaryColor;
    // margin-right: 2%;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.5);

    &:hover {
      transform: scale(1.05);
      color: $secondaryColor;
      border: 1px solid #fd5bb2;
    }

    // &:hover {
    //   color: $primaryColor !important;
    //   background-color: $appBGColor !important;
    //   border-radius: 2px;
    // }

    .application-icon-1 {
      font-size: 30px !important;
    }

    .application-icon-hover-1 {
      font-size: 30px !important;
      color: $secondaryColor;
    }

    .application-title-1 {
      margin: 0px;
      text-align: center;
      font-size: small;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      box-sizing: border-box;
      text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
    }

    .application-card-item-1 {
      color: $primaryColor;
    }
  }
  .button-card-1 {
    width: $appSideHomePage;
    height: $appSideHomePage;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px dashed $secondaryColor;
    background-color: #fff0f7;
    color: $secondaryColor;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.5);
    text-decoration: none;

    &:hover {
      transform: scale(1.05);
      border-radius: 3px;
    }

    .application-icon-1 {
      font-size: 30px !important;
    }
    .application-title-1 {
      margin: 0px;
      text-align: center;
      font-size: small;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      box-sizing: border-box;
      text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
    }

    .application-card-item-1 {
      color: #d8477e;
    }
  }
}

.searchbar-home {
  div:first-of-type {
    width: 100%;
  }
  input {
    width: calc(100% - 80px);
  }
}

.search-edit-container {
  display: flex;
  align-items: center;
}
