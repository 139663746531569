@import "../../../styles/sass/NewThemeVariables.scss";

.undermaintain-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  // background: $primaryColor;
  width: 100%;
  height: 100%;
}
.UM-pink{
  color: $secondaryColor;
}

.UM-charcoal{
  color: $primaryColor;
}

.UM-text {
font-size: 20px;
font-family: $fontFamily;
font-weight: 600;
}
