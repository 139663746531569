@import "../../../../styles/sass/NewThemeVariables.scss";

.primary {
  color: $primaryColor;
  font-size: $primaryFontSizeS;
  font-weight: bolder;
}

.secondary {
  color: $primaryColor;
  font-size: 13px;
}

.no-assets-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6%;
  color: $primaryColor;
  font-size: $primaryFontSizeS;
  img {
    height: 15vh;
    width: 100%;
  }
}

.view-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .view-link-button {
    background-color: $primaryFontColorDark;
    height: 28px;
    /* padding: 10px 5px; */
    margin-right: 10px;
    font-size: $primaryFontSizeXS;
    font-family: $fontFamily;
    width: 85px;
    color: $fifthColor;
    border: 1px solid $fifthColor;
    &:hover {
      color: $primaryFontColorDark;
      background-color: $fifthColor;
    }
  }
  .favourite-icon {
    color: $secondaryColor;
    font-size: $primaryFontSizeXXL;
  }
}
