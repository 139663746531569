/* @font-face {
    font-family: 'Energy';
    src: local('Energy'),
         /* url("../fonts/Energy\ Font/OTF/Energy-Bold") format('otf'), */
/* url("../fonts/Energy\ Font/OTF/Energy-Regular") format('otf'), */
/* url("../fonts/Energy\ Font/OTF/Energy-Light") format('otf'),
} */

@font-face {
  font-family: "Energy";
  src: url("../fonts/Energy\ Font/OTF/Energy-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Energy";
  src: url("../fonts/Energy\ Font/OTF/Energy-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Energy";
  src: url("../fonts/Energy\ Font/OTF/Energy-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
