@import "../../../styles/sass/NewThemeVariables.scss";

.bi-report {
  height: calc(100vh - 73px) !important;
  padding: 0px !important;
  width: 100%;
  .dark-report {
    background-color: $darkThemeDarkCharcoal;
    color: $primaryColor;
  }
  .light-report {
    background-color: $lightThemeGreyBg;
    color: $lightThemeWhiteBg;
  }
}
