@import "../../../styles/sass/NewThemeVariables.scss";

.add-card {
  border: 2px solid #d8477e;
}

.applications-card-container-1 {
  width: 225px;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: all 0.1s linear;
  border-radius: 3px;
  .application-1 {
    width: 225px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    // border: 2px solid $inputEleBlackBorderColor;
    // margin-right: 2%;
    box-sizing: border-box;
    &:hover {
      .app-status-home-icon {
        display: block !important;
      }
    }

    &.dark {
      background-color: $darkThemelightCharcoal !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      color: $lightThemeWhiteBg;
    }

    &.light {
      background-color: $lightThemeWhiteBg !important;
      box-shadow: 0px 5px 15px 0px rgba(70, 90, 105, 0.08);
      color: $primaryColor;
    }

    &:hover {
      transform: scale(1.05);
      color: $secondaryColor;
      border: 1px solid #fd5bb2;
    }

    // &:hover {
    //   color: $primaryColor !important;
    //   background-color: $appBGColor !important;
    //   border-radius: 2px;
    // }

    .application-icon-1 {
      font-size: 26px !important;
      color: #aab4bd;
    }

    .application-icon-hover-1 {
      font-size: 28px !important;
      color: $secondaryColor;
    }

    .application-title-1 {
      margin: 0px;
      text-align: center;
      font-size: small;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      padding: 0 5px;
      -webkit-box-orient: vertical;
    }

    .application-type-1 {
      margin: 0px;
      text-align: center;
      font-size: small;
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      box-sizing: border-box;
      color: #aab4bd;
    }

    .application-card-item-1 {
      color: $primaryColor;
    }
  }
}

.new-theme-add-card {
  width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration: none;
  background-color: #0a449c;
  color: white;
}

.primary-text {
  font-size: 23px;
  font-weight: $fontWeightBold;
}

.secondary-text {
  font-size: $primaryFontSizeL;
  font-weight: $fontWeightLight;
}

.fav-icon-visible {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 16px !important;
  color: #ffc700 !important;
  &:hover {
    color: #eca3bf !important;
  }
}
.app-status-icon-schedule {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 12px !important;
  color: #ed8a2c !important;
}
.app-status-icon-approved {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 12px !important;
  color: #55b82a !important;
}

.app-status-home-icon {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 16px !important;
  color: $secondaryColor !important;
  display: none !important;
}
.app-status-icon-rejected {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 12px !important;
  color: #df635d !important;
}
.fav-icon-hover {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  font-size: 16px !important;
  color: $primaryColor;
  &:hover {
    color: #eca3bf !important;
  }
}
.info-icon-hover {
  position: absolute !important;
  right: 25px !important;
  top: 5px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  &:hover {
    color: goldenrod !important;
  }
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.dark-info-card {
    color: $lightThemeWhiteBg;
  }
  &.light-info-card {
    color: $primaryColor;
  }
}

.info-container-hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondaryColor;
}

.shakable-app-card {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
