@import "../../../styles/sass/NewThemeVariables.scss";
.new-theme-search-container {
  border-radius: 3px;
  border: 1px solid #b2b1b1;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 2;
  &.dark {
    background-color: $darkThemelightCharcoal;
    color: $lightThemeWhiteBg;
  }
  &.light {
    color: $darkThemelightCharcoal;
    background-color: $lightThemeWhiteBg;
  }
}
