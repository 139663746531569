.Help-center-tickets-container {
  height: calc(100vh - 163px);
  overflow: auto;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 7px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 50px;
  padding: 0px 20px;
  :disabled {
    opacity: 50%;
  }
}

.expanded-ticket-description{
  margin: 0px;
  font-size: 14px;
  font-family: "Energy";
  white-space: pre-wrap;
}

.collapsed-ticket-description{
  word-break: break-all;
  margin: 0px;
  font-size: 14px;
  font-family: "Energy";
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

.ticket-card-description {
  margin: 5px 0px 0px 0px;
  font-size: 11px;
  font-family: "Energy";
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ticket-card-summary {
  font-size: 15px;
  font-family: "Energy";
  font-weight: bold;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 5px 0px 0px 0px;
}
