@import "../../../styles/sass/NewThemeVariables.scss";

.main-header-text {
  font-family: $HeaderFont;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 81.818% */
  letter-spacing: 0.1px;
}
.text-dark {
  color: #fff;
}
.text-light {
  color: #000;
}

.header-icon-app-status-style {
  font-size: 25px;
  margin-left: 10px;
  margin-top: 5px;
  cursor: default;
}

.status-schedule {
  color: #ed8a2c !important;
}
.status-approved {
  color: #55b82a !important;
}
.status-rejected {
  color: #df635d !important;
}
.status-published {
  color: #fc007f !important;
}

header {
  display: flex;
  height: $headerHeight;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0px 10px;
  position: fixed;
  width: calc(100vw - 60px);
  z-index: 1;

  .rb-logo-container {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    .rb-logo {
      height: $rbLogoHeight;
      width: auto;
    }
  }
  .top-left-nav {
    display: flex;
    align-items: center;
    // padding-left: 2%;
    .rb-logo-text {
      color: $sidenavActiveFontColor;
      // border-left: 1px solid $headerIconColor;
      margin: 0px;
      padding: 20px 0px 10px 20px;
      font-weight: 600;
      font-size: 18px;
      text-decoration: none;
      .header-text {
        display: flex;
        justify-content: center;
        align-items: center;
        .left-icon {
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }
  }

  .header-menu {
    display: flex;
    align-items: center;

    .user-name {
      color: white;
      margin-right: 7px;
      font-size: 15px;
    }
  }

  .notification-container,
  .all-apps-container,
  .user-info-container,
  .favourite-icon-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: $primaryFontSizeS;

    // &:hover {
    //   // background-color: #546c7f;
    // }

    .header-menu-icon {
      font-size: $headerIconSize;
      width: $headerIconSize;
      //margin-left: $headerIconMarginRight;

      &.notification {
        position: relative;
        //   &.badge:after{
        //     content:"100";
        //     position: absolute;
        //     background: $secondaryColor;
        //     height:8px;
        //     top:1px;
        //     right:1px;
        //     width:8px;
        //     text-align: center;
        //     //line-height: 1rem;;
        //     font-size: 1rem;
        //     border-radius: 50%;
        //     color:white;
        //     border:1px solid $secondaryColor;
        // }
      }
    }
  }
}

header.light {
  background-color: $headerBGLight;
  box-shadow: 0px 4px 10px rgba(221, 221, 221, 0.25);
  .header-menu-icon {
    color: $headerIconColor;
  }
}

header.dark {
  background-color: $headerBGDark;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  .header-menu-icon {
    color: $headerIconColorDark;
  }
}

.banner {
  position: fixed;
  top: $headerHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $fourthColor;
  color: $primaryColor;
  width: 100%;
  padding: 0px 10px;
  p {
    font-size: 11px;
    margin: 0px;
    margin-right: 4px;
  }
  a {
    color: $primaryColor;
    font-size: 11px;
  }
}

header.light-account-setup {
  background-color: $headerBGLight;
  width: 100vw;
  box-shadow: 0px 4px 10px rgba(221, 221, 221, 0.25);
  .header-menu-icon {
    color: $headerIconColor;
  }
}

header.dark-account-setup {
  background-color: $headerBGDark;
  width: 100vw;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  .header-menu-icon {
    color: $headerIconColorDark;
  }
}

.back-button {
  position: absolute;
  left: 10px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 18px;
  width: 99px;
  border-radius: 3px;
  background: none;
}

.draft-button-header {
  background: #ed8a2c;
  color: #fff;
  font-style: normal;
  border: 1px solid #ed8a2c;
  width: 148px;
  height: 28px;
  font-weight: 400;
  :disabled {
    opacity: 0.5;
  }
}

.selected-header {
  color: #fc007f;
  font-weight: 400;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
  font-family: "Energy";
  width: 148px;
  height: 28px;
  border-radius: 3px;
  border: 1px solid #0058aa;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background-color: #0058aa;
  color: #ffffff;
}
