@import "../../../styles/sass/NewThemeVariables.scss";

.applications-container {
  .aide-text {
    width: 28%;
    align-items: center;
    justify-content: space-between;
    float: left;
    height: 100%;
    margin-right: 2%;
    padding: 9%;
    color: $primaryColor;
    line-height: 160%;
  }

  .aide-root {
    padding: 8px 8px;
    box-sizing: border-box;
    // padding-top: 9%;

    .applications-container {
      margin: 15px 0px;
      .applications-title {
        font-weight: $fontWeightBold;
      }

      .applications-title {
        font-weight: $fontWeightBold;
      }

      .applications-row {
        margin: 0px 0px 35px 0px;
        border-radius: 10px;

        .applications-row-header {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
        }

        .applications-row-view {
          cursor: pointer;
          font-size: 13px;
          color: #282828;
          font-weight: $fontWeightNormal;
          text-decoration: underline;
          text-decoration-line: 2px;
          text-underline-offset: 2px;
        }

        .applications-row-title {
          margin: 0px 0px 8px 0px;
          font-size: 18px;
          color: $secondaryColor;
          font-weight: $fontWeightBold;
          font-style: bolder;
        }

        .applications-row-grid {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax($appSideHomePage, 0.1fr)
          );
          grid-gap: $appRowGridGapHomePage;

          .applications-card-container {
            width: 120px;
            color: white;
            position: relative;
            display: flex;
            cursor: pointer;
            transition: all 0.1s linear;
            .application {
              width: $appSideHomePage;
              height: $appSideHomePage;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              // box-shadow: 0px 4px 10px rgb(221 221 221 / 75%);
              // border: 2px solid $inputEleBlackBorderColor;
              // background-color: $appBGColor;
              color: $primaryColor;
              // margin-right: 2%;
              box-sizing: border-box;
              box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.5);

              &:hover {
                transform: scale(1.05);
              }

              &:hover {
                background-color: $primaryColor !important;
                color: $appBGColor !important;
                border-radius: 3px;
              }

              .application-icon {
                font-size: $appIconSizeLarge;
              }

              .application-title {
                margin: 0px;
                text-align: center;
                font-size: small;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                box-sizing: border-box;
                text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
              }

              .application-card-item {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}

.img-container {
  width: 300px;
  height: 300px;
}

.primary-text {
  font-size: 23px;
  font-weight: $fontWeightBold;
}

.secondary-text {
  font-size: $primaryFontSizeL;
  font-weight: $fontWeightLight;
}

.filter-btn {
  color: $primaryColor !important;
  background-color: #fff !important;
  height: 100%;
  &-selected {
    background-color: $primaryColor !important;
    color: #fff !important;
    height: 100%;
  }
}

.heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #282828 !important;
  margin-bottom: 5px;
  margin-top: 10px;
  &-auto {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: #282828 !important;
    margin-left: 15px;
    margin-top: 10px;
  }
}

.pop-up-message-style {
  font-size: 14px;
  margin: 0px -6px;
  font-weight: 400;
}

.search {
  display: flex;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  // margin-bottom: 20px;
  flex: 2;
  box-shadow: 0px 5px 15px 0px #00000014;
  &.dark {
    background-color: $darkThemelightCharcoal;
    color: $lightThemeWhiteBg;
  }
  &.light {
    color: $darkThemelightCharcoal;
    background-color: $lightThemeWhiteBg;
  }
}
.error {
  color: red;
  font-size: 11px;
  // margin-left: 8px;
}
.not-found {
  color: #d8477e;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: -40px;
  margin-top: 70px;
}

.active-new::-webkit-input-placeholder {
  color: blue;
}

.RB-header-viewby-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.error-image-container {
  display: flex;
  justify-content: center;
}

.explore-apps-container {
  overflow-y: auto;
  // height: calc(100vh - 310px);
  // height: calc(100vh - 250px);
  // height: calc(100vh - 190px);
  height: calc(100vh - 95px);
  // .dark {
  //   background-color: #000000 !important;
  // }
  // .light {
  //   background-color: #f5f5f7 !important;
  // }
}

.apps-container-RAC {
  background: white;
  display: flex;
  padding: 10px 10px;
  // width: 95%;
  border-radius: 10px;
}

.recommended-apps-header {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.labelText {
  color: #8d9ca6;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.labelTextforFilters {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  .dark {
    color: #000;
  }
  .light {
    color: #fff;
  }
}

.light-autoCompeteContainer {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #282828;
    opacity: 1;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #282828;
    opacity: 1;
  }
}
.dark-autoCompeteContainer {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #fff;
    opacity: 1;
  }
}

.applications-row-grid-allapps {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 0.1fr));
  grid-gap: $appRowGridGapHomePage;
}

.all-apps-container-above-grid {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
