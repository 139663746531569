.dashboard {
    &-temp4 {
        &-dark {
            background-color: #282828;
            color: #fff;
            // background-color: #000000;
            // background-color: #000000;
            // box-shadow: 0 5px 15px #282828;
        }
        &-light {
            background: #ffffff;
            color: #282828;
            border: 0.2px solid #e7e9ee;
        }
        &-container {
            height: calc(100vh - 220px);
            overflow: auto;
            padding-bottom: 12px;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        &-card-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            font-family: "Energy";
            color: #282828;
            margin-top: 30px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
        }
        &-inner-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
        }
        &-card {
            // background: #ffffff;
            // color: #282828;
            // border: 0.2px solid #e7e9ee;
            // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            text-align: center;
            // width: 325px;
            // height: 375px;
            padding: 30px 15px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            // flex: 1;
            // min-width: 300px;
            // max-width: 450px;
            flex: 0 0 31.33%;
            height: 95%;
            box-sizing: border-box;
            & p {
                text-align: center !important;
                margin: 0;
                white-space: pre-wrap;
            }
        }
        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 35px;
        }
        &-desc {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            // white-space: pre-wrap;
            overflow: hidden;
            overflow-wrap: anywhere;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            text-align: center;
        }
        &-img {
            // width: 140px;
            height: 130px;
            // margin-top: 25px;
        }
        &-additional-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            img {
                // height: 40px;
                max-height: 40px;
                max-width: 50px;
            }
            span {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
