//Body
$fontFamily: "Energy" !important;
// $fontFamily: "Montserrat" !important;

//theme colors
$darkThemeDarkCharcoal: #000000;
$darkThemelightCharcoal: #282828;
$lightThemeWhiteBg: white;
$lightThemeGreyBg: #f7f7f7;

//Header
$HeaderFont: "Energy" !important;
$headerHeight: 52px;
$headerIconSize: 22px;
$headerIconMarginRight: 20px;
$rbLogoHeight: 30px;
$primaryHeaderColor: #fc007f;
$headerBGLight: white;
$headerBGDark: #282828;
$headerIconColor: #282828;
$headerIconColorDark: white;

//Footer
$footerHeight: 25px;
$footerFontColor: #ffffff;
$footerFontDark: #000000;

//Sidenav
$sidenavWidth: 238px;
$totalHeaderFooterHeight: 65px; //header 40px + footer 25 px
$sidenavIconSize: 20px;
$sidenavTextSize: 12px;
$sidenavItemPadding: 10px 25px 10px 12px;
$sidenavItemMarginBottom: 10px;
$sidenavIconMarginRight: 15px;
$sidenavActiveBGColorLight: #fbedf2;
$sidenavActiveBGColorDark: #3f3d56;
$sidenavActiveFontColor: #fc007f;
$sidenavBorderColor: #e2e2e2;
$sidenavBoxShadow: 5px 0px 4px -3px #e2e2e2;
$sidenavWidthExpanded: 335px;
$sidenavWidthCollapsed: 65px;
$sidenavBGColorLight: #f1f2f7;
$activeDatasetColor: #dff2f3;

//App
$appSide: 130px;
$appSideHomePage: 100px;
$appIconSize: 25px;
$appIconSizeLarge: 30px;
$appIconSizeXL: 40px;
$appFontSize: 14px;
$appLineHeight: 23px;
$appRowGridGap: 2%;
$appRowGridGapHomePage: 15px;
$appRowGridGapConfig: 5%;
$appColumnGridGap: 2.5%;
$appBGColor: #f1f1f1;
// $appBGColorLight: #fcfcfe;
$appBGColorLight: #f9f9f9;
$appBGColorDark: #dfe1e6;
$primaryFontcolor: #1e212f;

//Filters
$filtersPadding: 15px;
$filtersMargin: 20px 0px;
$filterItemHeight: 60px;

//Icon size
$iconS: 12px;
$iconM: 13px;
$iconL: 14px;

//Common
$newPrimaryColor: #0158aa;
$primaryColor: #282828;
$secondaryColor: #fc007f;
$fifthColor: #25b1e6;
$fourthColor: #f5c405;
$fifthColor: #4eafb3;
$errorColor: red;
$deleteColor: red;
$primaryFontColorDark: #ffffff;
$primaryFontSizeXXS: 11px;
$primaryFontSizeXS: 12px;
$primaryFontSizeS: 13px;
$primaryFontSizeM: 15px;
$primaryFontSizeL: 16px;
$primaryFontSizeXL: 18px;
$primaryFontSizeXXL: 20px;
$primaryFontSizeXXXL: 22px;
$paddingAround: 15px 15px 15px 15px;
$paddingAroundSingle: 2px;
$mainWidthExpanded: calc(100% - #{$sidenavWidthExpanded});
// $mainWidthExpanded: calc(100% - 10px);
$mainWidthCollapsed: calc(100% - #{$sidenavWidthCollapsed});
$mainHeight: calc(100vh - #{$totalHeaderFooterHeight});
$primaryBR: 4px;
$inputEleBorderColor: #dedede;
$inputEleBlackBorderColor: #282828;
$inputEleHeight: 26px;
$inputEleWidth: 190px;
$inputElementWidth: 225px !important;
$inputLeftSpacing: 3px;
$fontWeightBold: 900;
$fontWeightNormal: 700;
$fontWeightLight: 400;
$transition: 0.2s ease-out;
$iconSizeSmall: 18px;
$iconSizeM: 20px;
$iconsizeL: 22px;
$inputSearchBGColor: #f7f7f7;
$modelSelectionMinHeight: calc(100vh - 350px);
$modelSelectionMaxHeight: calc(100vh - 350px);
$noDataAvailableMinHeight: calc(100vh - 450px);
$noDataAvailableMaxHeight: calc(100vh - 450px);
