@import "../../../styles/sass/NewThemeVariables.scss";

nav.new-theme-nav {
  font-size: 8px !important;
  font-weight: 400;
  width: 60px;
  background-color: #282828;
  display: flex;
  flex-direction: column;
  align-items: center;
  // &.dark{
  //   background-color:#282828;
  //   box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
  // }
  // &.light{
  //   background-color: #282828;
  //   box-shadow: 0px 4px 10px rgba(221, 221, 221, 0.25);
  // }
}

.logo-image {
  margin: 13px 0px;
  width: 30px;
}
.sidenav-elements-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
.nav-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  padding: 2px;
  border-radius: 3px;
  margin: 4px 0px;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.nav-row-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  height: 50px;
  padding: 2px;
  border-radius: 3px;
  margin: 4px 0px;
  justify-content: center;
  color: $secondaryColor !important;
}

.row-name {
  margin: 4px;
}

.nav-icon {
  margin-right: 10px;
  padding-left: 8px;
  padding-top: 2px;
  color: #282828;
}

.nav-title {
  font-size: 14px;
  margin-left: 10px;
}
