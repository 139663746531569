.help-center {
  font-family: "Energy";
  color: #282828;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 75px);
    overflow-y: auto;
  }
  &-inner-container {
    padding: 20px 40px 40px 40px;
  }
  &-card {
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin: 40px 0;
    &-dark {
      background-color: #000000 !important;
      box-shadow: 0 5px 15px #282828;
      .help-center-title {
        color: #fff !important;
      }
      .help-center-multiselect {
        box-shadow: none;
        // border-color: #e6e6e6;
      }
      .help-center-upload-box {
        border-color: #fff !important;
      }
      .help-center-rating-btn {
        background-color: #282828;
        color: #fff;
      }
      .help-center-rating-options {
        background-color: #282828;
        color: #fff;
      }
      .help-center-select-field {
        background-color: #282828;
      }
      .help-center-drop-desc {
        color: #fff;
      }
    }
  }
  &-title {
    font-family: "Energy";
    color: #282828;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 22px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  &-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #8d9ca6;
    span {
      color: #fc007f;
    }
  }
  &-upload {
    &-box {
      border: 1px dashed rgba(66, 89, 108, 0.25);
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 22px;
      position: relative;
      flex-direction: column;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &-file {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #267ee6;
      cursor: pointer;
    }
    &-img {
      height: 100px;
    }
  }
  &-warning-text {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 5px;
  }
  &-warning-email {
    color: red;
    font-size: 12px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: -30px;
  }
  &-desc-warning {
    display: flex;
  }
  &-submit {
    background: #fc007f;
    color: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    height: auto;
    padding: 5px;
    width: 180px;
    height: 38px;
    &:disabled {
      background-color: rgba(252, 0, 127, 0.5);
    }
  }
  &-multiselect {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // border: 1px solid rgba(66, 89, 108, 0.25);
    // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    gap: 5px;
    padding: 3px 0px;
    border-radius: 3px;
    position: relative;
  }
  &-req-category {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }
  &-req-name {
    color: #fc007f;
  }
  &-req-desc {
    color: #282828;
  }
  &-req-icon {
    color: #fc007f;
  }
  &-rating {
    &-container {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
      margin-top: 5px;
    }
    &-btn {
      background: rgba(255, 255, 255, 0.2);
      border: 0.2px solid #e7e9ee;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #282828;
      width: 33px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &-selected {
        background: #fc007f !important;
        color: #fff !important;
      }
    }
    &-options {
      background: #ffffff;
      border: 0.2px solid #e7e9ee;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      color: #282828;
      // width: 200px;
      // padding: 5px 0;
      padding: 5px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      &-selected {
        background: #fc007f !important;
        color: #fff !important;
        // border: 0.2px solid #fc007f !important;
      }
    }
  }
  &-attachment {
    &-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 14px;
      margin-top: 10px;
    }
    &-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: absolute;
      bottom: 0px;
      background-color: rgba(255, 255, 255, 0.7);
      width: 100%;
    }
    &-delete {
      font-size: 20px !important;
      color: #282828;
    }
    &-filename {
      font-size: 14px;
      color: #282828;
      // width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-openfile {
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: rgba(255, 255, 255, 0.5);
      span {
        font-size: 20px !important;
        // color: black;
        color: #282828;
      }
    }
  }
  &-drop {
    &-label {
      font-family: Energy;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #fc007f;
    }
    &-desc {
      font-family: Energy;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #282828;
    }
  }
  &-select {
    &-field {
      background-color: #eff1f4;
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        :focus {
          border: 0.2px solid #e7e9ee;
        }
      }
    }
    &-paper {
      background-color: #f7f9fc;
    }
    &-dropdown {
      .MuiMenuItem-root:hover {
        background-color: transparent;
      }
      .MuiMenuItem-root:focus {
        background-color: transparent;
      }
    }
  }
}
