@import "../../../styles/sass/NewThemeVariables.scss";

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background: $primaryColor;
  vertical-align: middle;
  margin-right: 0.6em;
  color: white;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  color: $primaryColor;
  font-size: 14px;
  padding-left: 15px;
}

.comments-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 10px;
  &.dark-comment {
    color: $lightThemeWhiteBg;
  }
  &.light-comment {
    color: $primaryColor;
  }
}

.view-meta {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.send-container {
  display: flex;
  padding: 0px 20px;
  align-items: center;
  position: fixed;
  bottom: 0px;
  &.dark-send-container {
    background-color: $darkThemeDarkCharcoal;
    color: $lightThemeWhiteBg;
  }
  &.light-send-container {
    background-color: $lightThemeWhiteBg;
    color: $primaryColor;
  }
  .commented_by_user {
    margin: 0px;
    font-size: 15px;
    // font-weight: 900;
  }
  .comment-input {
    padding: 7px 15px;
    border-radius: 20px;
    width: 335px;
    border: 1px solid #e6e6e6;
    font-size: $primaryFontSizeXS;
    &.dark-comment-input {
      background-color: black;
      color: $lightThemeWhiteBg;
    }
    &.light-comment-input {
      background-color: $appBGColor;
      color: $primaryColor;
    }
  }
  .send-button {
    font-size: 14px;
    padding-left: 15px;
    cursor: pointer;
    &.dark-send {
      background-color: $darkThemeDarkCharcoal;
      color: $lightThemeWhiteBg;
    }
    &.light-send {
      background-color: $lightThemeWhiteBg;
      color: $primaryColor;
    }
  }
  .disabled {
    opacity: 0.6;
    pointer-events: none;
    font-size: 14px;
    padding-left: 15px;
    &.dark-send {
      background-color: $darkThemeDarkCharcoal;
      color: $lightThemeWhiteBg;
    }
    &.light-send {
      background-color: $lightThemeWhiteBg;
      color: $primaryColor;
    }
  }
}

.power-bi-report-container {
  height: 100%;
  width: 100%;
  padding: -30px -30px -15px -30px;
}
#power-bi-report-iframe-container {
  width: 100%;
  height: 100%;
  .iframe-container {
    height: calc(100vh - 120px);
  }
  .comment-faq-div {
    display: flex;
    height: 35px;
    box-sizing: border-box;
    align-items: center;
    padding: $sidenavItemPadding;
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    .dark-comment-section {
      color: $lightThemeWhiteBg;
    }
    .light-comment-section {
      color: $primaryColor;
    }

    .visibility {
      cursor: default;
      display: flex;
      flex-direction: row;
    }

    .nav-icon {
      width: $sidenavIconSize;
      font-size: $sidenavIconSize;
      margin-right: 10px;
      &.visibility {
        cursor: default;
      }
      &.dark-icon {
        color: $lightThemeWhiteBg;
        background: transparent;
      }
      &.light-icon {
        color: $primaryColor;
        background: transparent;
      }
    }

    .nav-text {
      margin: 0px;
      margin-right: $sidenavIconMarginRight;
      display: flex;
      font-size: $sidenavTextSize;
      justify-content: left;
      align-items: center;
      &.visibility {
        cursor: default;
      }
      &.dark-text {
        color: $lightThemeWhiteBg;
      }
      &.light-text {
        color: $primaryColor;
      }
    }
  }
}

.trouble-signin {
  display: flex;
  font-size: $sidenavTextSize;
  cursor: pointer;
  align-items: center;
  img {
    width: 25px;
    margin-right: 10px;
  }
  &.light-text {
    color: $primaryColor;
  }
  &.dark-text {
    color: #fff;
  }
  span {
    cursor: pointer;
    color: #fc007f;
    padding-left: 5px;
  }
}

.Embed-container {
  height: 100%;
}

.folder-Container {
  margin-left: 10px;
  width: 272px;
}

.folder {
  font-size: 14px;
  font-family: "ENERGY";
  margin-right: 10px;
  margin-top: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 3px;
  display: flex;
  // justify-content: space-between;
}

.report {
  font-family: "Energy";
  font-size: 14px;
  margin-right: 10px;
  margin-top: 2px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 3px;
  &-light {
    color: #000;
  }
  &-dark {
    color: #fff;
  }
}

.report-light:hover {
  background-color: #fff;
}
.report-dark:hover {
  background-color: #282828;
}

.active-report-light {
  background-color: #fff !important;
  color: #fc007f !important;
}

.active-report-dark {
  background-color: #282828 !important;
  color: #fc007f !important;
}

.expand-button {
  left: 449px;
  position: absolute;
  background: #282828;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  height: 25px;
  width: 25px;
  border-radius: 115px;
  cursor: pointer;
}

.collapsed-button {
  left: 51px;
  position: absolute;
  background: #282828;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  height: 25px;
  width: 25px;
  border-radius: 115px;
  cursor: pointer;
}
