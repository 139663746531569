@import "../../../styles/sass/NewThemeVariables.scss";

.infoPara-light {
  margin: 0px 5px 0px 0px;
  font-size: 11px;
  font-weight: 400;
  color: #000;
}

.infoPara-dark {
  margin: 0px 5px 0px 0px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
}

.app-icon {
  padding: 5px;
  border-radius: 32px;
  border: 1px solid #0000002e;
}

.dark-icon {
  background: #282828;
  color: #ffffff;
}
.light-icon {
  color: $secondaryColor;
  background: #fff;
}



