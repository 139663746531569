@import "../../../styles/sass/NewThemeVariables.scss";

section.body {
    display: flex;
    padding-top: $headerHeight;
    // min-height: calc(100vh - 85px);
    padding-bottom: 25px;

    &.not-full {
        min-height: unset;
    }
    main.content-container {
        // padding: 0px !important;
        padding: $paddingAround !important;
        height: $mainHeight;
        overflow-y: auto;
        transition: all $transition;
        &.main-expanded-container {
            // padding: 0px !important;
            width: $mainWidthExpanded;
            box-sizing: border-box;
            // background: #f7f7f7;
        }

        &.main-collapsed-container {
            // padding: 0px !important;
            width: $mainWidthCollapsed;
            // background: #f7f7f7;
        }
        &.dark {
            background-color: #000000;
        }
        &.light {
            background-color: #f7f7f7;
        }

        &.padding-none {
            padding: 0px;
        }
    }

    main.content.full {
        width: 100%;
        // height: auto !important;
    }

    .data-assets-iframe-container {
        height: $mainHeight;
        width: 100%;
    }

    .data-assets-loader-container {
        display: flex;
        height: 200px;
        width: 200px;
        align-items: center;
        margin: 0 auto;
    }

    main.content.power-bi-report {
        height: calc(100vh - 73px) !important;
        padding: 0px !important;
        background: #f7f7f7;
        width: 100%;
    }
    .no-assets-iframe-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 8% 4%;
        color: $primaryColor;
        font-size: $primaryFontSizeS;
        margin: 0 auto;
        img {
            height: 30vh;
            width: 100%;
        }
    }
}
