@import "../../../styles/sass/NewThemeVariables.scss";

.pnf-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  // background: $primaryColor;
  width: 100%;
  height: 100%;

  .button-pnf {
    border-radius: 50px;
    height: 30px;
    width: 200px !important;
    font-weight: 600;
  }
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .page-not-found-title {
      color: #cacaca;
    }

    .page-not-found-icon {
      color: #cacaca;
      font-size: 40px;
      margin-right: 10px;
    }
  }
}
