@import "../../../styles/sass/NewThemeVariables.scss";

.applications-container-mng {
  font-family: $fontFamily;
  .aide-text-mng {
    width: 28%;
    align-items: center;
    justify-content: space-between;
    float: left;
    height: 100%;
    margin-right: 2%;
    padding: 9%;
    color: $primaryColor;
    line-height: 160%;
  }

  .main-container-mng {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .img-container-mng {
    display: flex !important;
    justify-content: center !important;
  }

  .aide-root-mng {
    // padding: 30px;
    padding: 1.5% 1.5%;
    box-sizing: border-box;
    // padding-top: 9%;

    .applications-container-mng {
      margin-top: 0px;
      margin-bottom: 30px;
      .applications-title-mng {
        font-weight: $fontWeightBold;
        color: $primaryColor;
      }
      .applications-row-mng {
        margin: 0px 0px 35px 0px;
        border-radius: 10px;

        .applications-row-header-mng {
          display: flex !important;
          align-items: center !important;
          justify-content: space-between !important;
          margin-bottom: 10px;
        }

        .applications-row-view-mng {
          cursor: pointer;
          font-size: 13px;
          color: #282828;
          font-weight: $fontWeightNormal;
          text-decoration: underline;
          text-decoration-line: 2px;
          text-underline-offset: 2px;
        }

        .applications-row-title-mng {
          margin: 0px 0px 8px 0px;
          font-size: 18px;
          color: $secondaryColor;
          font-weight: $fontWeightBold;
          font-style: bolder;
        }

        .applications-row-grid-mng {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(225px, 0.1fr)
          );
          grid-gap: $appRowGridGapHomePage;

          .applications-card-container-mng {
            width: 120px;
            color: white;
            position: relative;
            display: flex;
            cursor: pointer;
            transition: all 0.1s linear;
            .application-mng {
              width: $appSideHomePage;
              height: $appSideHomePage;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              // border: 2px solid $inputEleBlackBorderColor;
              // background-color: $appBGColor;
              color: $primaryColor;
              // margin-right: 2%;
              box-sizing: border-box;
              box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.5);

              &:hover {
                transform: scale(1.05);
              }

              &:hover {
                background-color: $primaryColor !important;
                color: $appBGColor !important;
                border-radius: 3px;
              }

              .application-icon-mng {
                font-size: $appIconSizeLarge;
              }

              .application-title-mng {
                margin: 0px;
                text-align: center;
                font-size: small;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                box-sizing: border-box;
                text-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
              }

              .application-card-item-mng {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}

.primary-text-mng {
  font-size: 23px;
  font-weight: $fontWeightBold;
}

.secondary-text-mng {
  font-size: $primaryFontSizeL;
  font-weight: $fontWeightLight;
}

.No-apps-image-mng {
  height: 200px;
  margin-top: 100px;
}
