.form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #8d9ca6;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 6px;
    &-star {
        color: #fc007f;
    }
}
