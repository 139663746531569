@import "../../../styles/sass/NewThemeVariables.scss";

div.new-page-container {
  display: flex;
}

section.body-layout {
  display: flex;
  padding-bottom: $footerHeight;
  height: calc(100vh - #{$footerHeight}) !important;
  // min-height: calc(100vh - 85px);
  // padding-bottom: 25px;

  &.not-full {
    min-height: unset;
  }

  main.container-layout {
    height: $mainHeight;
    transition: all $transition;
    z-index: 0;

    &.main-collapsed {
      margin-top: 50px;
      // width: calc(100% - 10px) !important;
      width: calc(100vw - 60px) !important;
    }
  }

  main.content.full {
    width: 100%;
    // height: auto !important;
  }
}

section.layout-background {
  &.dark {
    background: #000000 !important;
  }

  &.light {
    background: #f7f7f7 !important;
  }
}
