@import "../../../styles/sass/NewThemeVariables.scss";

.profile {
    &-container {
        height: 100%;
    }
    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 49px;
        color: #fc007f;

        &-container {
            display: flex;
            align-items: center;
        }
    }
    &-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
    }
}

.light {
    &-profile-container {
        // background-color: #e5e5e5;
        color: #282828;
    }
}
.dark {
    &-profile-container {
        background-color: #000000;
        color: #fff;
    }
}
