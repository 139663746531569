// @import "../../../../styles/sass/NewThemeVariables.scss";

// Variables
$pink-color: #fc007f;
$lightgray-color: #e8e8e8;
$gray-color: #939493;
$darkGray-color: #282828;
$font-style: "Energy";

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usage-analysis {
  display: flex;
  align-items: baseline;
  color: $gray-color;
  font-weight: 700;
  font-family: $font-style;
  font-size: 16px;
}

.usage-report-charts {
  display: flex;
  align-items: baseline;
  color: $gray-color;
  font-weight: 700;
  font-family: $font-style;
  font-size: 16px;
  margin-left: -20%;
}

.selected-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $pink-color;
  margin-right: 5px;
}

.solid-line {
  width: 100%;
  height: 1px;
  background-color: #cccccc;
}

.calendarElement-light {
  position: relative;
  border: 1px solid #cccccc;
  & .rdrDayNumber span {
    color: #000 !important;
    font-family: "Energy";
  }
  & .rdrMonthAndYearPickers select {
    font-family: "Energy";
  }

  & .rdrDateDisplayItem input {
    font-family: "Energy";
  }
}

.calendarElement-dark {
  background: #282828 !important;
  position: relative;
  border: 1px solid #cccccc;

  & .rdrMonthAndYearPickers select {
    color: #fff !important;
    font-family: "Energy";
  }

  & .rdrDayDisabled {
    background: none !important;
  }

  & .rdrDayDisabled {
    & .rdrDayNumber span {
      color: #aeb9bf !important;
      font-family: "Energy";
    }
  }

  & .rdrDayPassive {
    & .rdrDayNumber span {
      color: #aeb9bf !important;
      font-family: "Energy";
    }
  }

  & .rdrDayNumber span {
    color: #fff !important;
    font-family: "Energy";
  }

  & .rdrDateDisplayItem input {
    font-family: "Energy";
  }

  & .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #000 !important;
  }
}

// .calendarElement span {
//   color: #fff !important; /* Change this color to your desired color */
// }

.button-light {
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 14px;
  font-family: $font-style;
  padding: 8px 5px 8px 8px;
  margin: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-light:hover {
  background-color: #ddf3fc;
}

.button-dark {
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 14px;
  font-family: $font-style;
  padding: 8px 5px 8px 8px;
  margin: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-dark:hover {
  color: #000;
  background-color: #ddf3fc;
}

.button-disabled-light {
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 14px;
  font-family: $font-style;
  padding: 8px 5px 8px 8px;
  margin: 4px;
  cursor: not-allowed;
  transition: background-color 0.3s;
  color: #afafaf;
}

.button-disabled-dark {
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 14px;
  font-family: $font-style;
  padding: 8px 5px 8px 8px;
  margin: 4px;
  color: #afafaf;
  cursor: not-allowed;
  transition: background-color 0.3s;
}

.input-box-light {
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: #282828;
  width: 100%;
  border: 1px solid rgba(66, 89, 108, 0.25);
  padding: 10px 0px;
  // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
}

.input-box-dark {
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  width: 100%;
  border: 1px solid rgba(66, 89, 108, 0.25);
  padding: 10px 0px;
  // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #282828;
}

.input-light {
  background: none;
  padding-left: 10px;
  width: 83%;
  font-size: 14px;
  border: none;
  color: #282828;
  cursor: pointer;
}

.input-dark {
  background: none;
  padding-left: 10px;
  width: 83%;
  font-size: 14px;
  border: none;
  color: #fff;
  font-family: "Energy";
  cursor: pointer;
}

.selected-report-light {
  color: #282828;
}
.selected-report-dark {
  color: #fff;
}

.selectedOption-light {
  color: #000 !important;
  background-color: #ddf3fc;
}
.selectedOption-dark {
  color: #000 !important;
  background-color: #ddf3fc;
}

.heading {
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-family: Energy !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 18px !important;
  // color: #000 !important;
  margin-bottom: 0;
  margin-top: 0px;
  padding-bottom: 2px;
  text-align: start;
}
