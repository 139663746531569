@import "../../../styles/sass/NewThemeVariables.scss";

.applications-row-title-style {
  margin: 0px 10px 3px 0px;
  text-transform: capitalize;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 200;
  &.dark {
    color: $lightThemeWhiteBg;
  }
  &.light {
    color: #000;
  }
}

.app-row-container-style {
  display: block !important;
}

.row-header-container-style {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 10px;
}

.show-app-title-style {
  font-family: $fontFamily;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 1px;
  cursor: pointer;
  &.dark {
    color: $lightThemeWhiteBg;
    border-bottom: 1px solid $lightThemeWhiteBg;
  }
  &.light {
    color: $newPrimaryColor;
    border-bottom: 1px solid $newPrimaryColor;
  }
}

.applications-row-grid-style {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(225px, 0.1fr)) !important;
  grid-gap: 10px !important;
}

.applications-row-icon-style {
  color: $secondaryColor;
  font-size: 24px;
  margin-right: 5px;
  margin-left: 30px;
  cursor: pointer;
}

.applications-row-container-style {
  display: flex;
  width: 200px;
  // border-bottom: 1px solid #000;
  // padding-bottom: 8px;
  align-items: center;
}

.row-title-input-container {
  font-family: $fontFamily;
  display: flex;
  justify-content: center;
  align-items: center;
}

// new
.container {
  display: flex;
  touch-action: none;
  width: 100%;
  height: 100%;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  // height: 300px;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 5px;
  // padding: 10px;
}

// .dropzone.left {
//   margin-right: 10px;
// }

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
}

.grid-item-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 0.5em;
}

.add-new-app-button {
  height: 20px;
  font-size: 10px;
  border-radius: 20px;
  font-weight: 800;
  border: 1px solid $secondaryColor;
  background-color: white;
  color: $secondaryColor;
  &:hover {
    color: white;
    background-color: $secondaryColor;
  }
}
