@import "../../../../styles/sass/NewThemeVariables.scss";

.my-profile-container {
  &.dark {
    background: #282828;
    color: white;
  }
  &.light {
    background: white;
    color: $primaryColor;
  }
  height: calc(100vh - 320px);
  border-radius: 3px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08);
  margin-top: 10px;
  overflow: auto;
  .top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px dashed #e6e6e6;
    padding: 20px;
    .profile-heading {
      margin: 0px !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .middle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    border-bottom: 1px dashed #e6e6e6;
    .profile-image-container {
      display: flex;
      .alphabet-picture {
        width: 100px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin-right: 20px;
        border: 5px solid #fecce5;
        .name-initials {
          font-size: 32px;
          font-weight: 600;
          color: white;
        }
      }
    }
    .profile-details-container {
      .profile-detail-item {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        .profile-detail-icon {
          color: $secondaryColor;
          cursor: default;
        }
        .profile-detail-value {
          font-size: 14px;
          margin: 0px 20px;
          text-transform: capitalize;
        }
      }
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .profile-bottom-header {
      font-size: 16px;
      font-weight: 600;
    }
    .bottom-profile-details {
      display: flex;
    }
    .bottom-profile-details {
      .preferences-item {
        display: flex;
        align-items: center;
        margin: 15px 0px;
        .preferences-label {
          display: flex;
          align-items: center;
          margin-right: 28px;
          width: 120px;
          .preferences-item-icon {
            cursor: default;
          }
        }
        .preferences-item-value {
          margin: 0px !important;
          font-size: 14px;
        }
      }
    }
  }
}

.profile-text {
  margin: 0px 14px !important;
  font-size: 14px;
}

.values-container {
  display: flex;
  .preferences-item-value {
    margin: 0px !important;
    font-size: 14px;
    margin-left: 4px !important;
    &.hover-on-more {
      cursor: default;
    }
  }
  .update-hyperlink-text {
    color: $secondaryColor;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px !important;
  }
}

.preferences-values {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 20px;
}

.preferences-edit-icon {
  font-size: 20px !important;
  color: $secondaryColor;
}
