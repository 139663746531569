@import "../../../styles/sass/NewThemeVariables.scss";

.jira-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  border: 2px solid #fff;
  background-color: #282828;
}
// .expanded-nav {
//  righ 220px;
// }
// .collapsed-nav {
//   left: 40px;
// }

.jira-icon {
  font-size: 20px !important;
  color: #fff;
}

.panorama-img-div {
  width: 20px;
  height: 20px;
  // margin-right: 15px;

  // &.country_competition {
  //   background-image: url(../../../assets/images/panorama-images/png_3.png);
  //   background-repeat: no-repeat;
  // }

  // &.imex_dashboard {
  //   background-image: url(../../../assets/images/panorama-images/imex_dashboard.png);
  //   background-repeat: no-repeat;
  // }

  // &.top_cmus {
  //   background-image: url(../../../assets/images/panorama-images/top_cmus.png);
  //   background-repeat: no-repeat;
  // }

  // &.country_financials {
  //   background-image: url(../../../assets/images/panorama-images/country_financials.png);
  //   background-repeat: no-repeat;
  // }

  // &.brand_financials {
  //   background-image: url(../../../assets/images/panorama-images/brand_financials.png);
  //   background-repeat: no-repeat;
  // }

  // &.panel_kpis_tracker {
  //   background-image: url(../../../assets/images/panorama-images/panel_kpis_tracker.png);
  //   background-repeat: no-repeat;
  // }

  // &.global_market_share_lysol {
  //   background-image: url(../../../assets/images/panorama-images/global_market_share_lysol.png);
  //   background-repeat: no-repeat;
  // }

  // &.usage_metrics {
  //   background-image: url(../../../assets/images/panorama-images/usage_metrics.png);
  //   background-repeat: no-repeat;
  // }
}

.nav-icon {
  font-size: 20px !important;
}

.active-row {
  color: #fc007f !important;
  border-radius: 3px;
  &.dark-bi {
    background: $darkThemelightCharcoal;
  }
  &.light-bi {
    background: $lightThemeWhiteBg;
  }
}

aside {
  position: relative;
  &.sidenav-power-bi-expanded {
    width: $sidenavWidthExpanded;
    box-shadow: none !important;
    border-right: 1px solid $sidenavBorderColor;
    box-sizing: border-box;
    height: 100%;

    nav {
      border-bottom: none !important;
      // padding-top: 30px !important;
      height: calc(100vh - 100px);
      overflow-y: auto;
      padding-left: 5px;

      .sidenav-category {
        border-top: 1px solid $sidenavBorderColor;

        .sidenav-title-category-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          &.expanded {
            padding: 10px 5px;
          }

          &.collapsed {
            padding: 10px 5px;
          }

          .sidenav-title-category {
            margin: 0px !important;
            font-size: 13px;
            // color: $primaryColor;
            // text-transform: uppercase;
            font-weight: 400;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }

          .sidenav-category-icon {
            font-size: 18px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }
        }
      }

      .sidenav-category-l2 {
        // border-top: 1px dashed #e2e2e2;
        // border-bottom: 1px dashed #e2e2e2;
        padding-left: 10px;
        padding-right: 10px;

        .sidenav-title-category-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          &.expanded {
            padding: 10px 5px;
          }

          &.collapsed {
            padding: 10px 5px;
          }

          .sidenav-title-category {
            margin: 0px !important;
            font-size: 13px;
            // color: $primaryColor;
            // text-transform: uppercase;
            font-weight: 400;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }

          .folder-icon {
            font-size: 18px;
            margin-right: 10px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }
        }
      }

      .sidenav-sub-category {
        border-bottom: none !important;
        // border-top: 1px dotted $sidenavBorderColor;

        // &:not(:last-child) {
        //   border-bottom: 1px dotted $sidenavBorderColor;
        // }

        .sidenav-title-sub-category-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 10px 5px 10px 15px;
          box-sizing: border-box;
          cursor: pointer;
          margin-bottom: 2px;

          &:hover {
            border-radius: 3px;
            color: #fc007f;
            &.dark-bi {
              background: $darkThemelightCharcoal;
            }
            &.light-bi {
              background: $lightThemeWhiteBg;
            }
          }

          .sidenav-title-sub-category {
            margin: 0px !important;
            font-size: 13px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
            font-weight: 400;
            &.active {
              color: #fc007f !important;
            }
          }

          .sidenav-sub-category-icon {
            font-size: 18px;
            margin-right: 15px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
            &.active {
              color: #fc007f !important;
            }
          }
        }
      }

      .nav-item {
        border: none !important;
        padding-left: 30px !important;

        .panorama-img-container {
          font-size: $appIconSize;
        }
        &.active,
        &:hover {
          border: none !important;
        }
      }
    }
  }
  &.sidenav-power-bi-collapsed {
    width: $sidenavWidthCollapsed;
    box-shadow: none !important;
    border-right: 1px solid $sidenavBorderColor;
    box-sizing: border-box;
    height: 100%;
    color: $primaryColor;
    nav {
      border-bottom: none !important;
      // padding-top: 30px !important;
      height: calc(100vh - 100px);
      overflow-y: auto;
      padding-left: 5px;

      .sidenav-category {
        border-top: 1px solid $sidenavBorderColor;

        .sidenav-title-category-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          &.expanded {
            padding: 10px 5px;
          }

          &.collapsed {
            padding: 10px 5px;
          }

          .sidenav-title-category {
            margin: 0px !important;
            font-size: 13px;
            // color: $primaryColor;
            // text-transform: uppercase;
            font-weight: 400;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }

          .folder-icon {
            font-size: 18px;
            margin-right: 10px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }

          .sidenav-category-icon {
            font-size: 18px;
            // color: $primaryColor;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
          }
        }
      }

      .sidenav-sub-category {
        border-bottom: none !important;
        // border-top: 1px dotted $sidenavBorderColor;

        // &:not(:last-child) {
        //   border-bottom: 1px dotted $sidenavBorderColor;
        // }

        .sidenav-title-sub-category-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 10px 5px 10px 15px;
          box-sizing: border-box;
          cursor: pointer;
          margin-bottom: 2px;

          &:hover {
            border-radius: 3px;
            &.light-bi {
              background: $lightThemeWhiteBg;
              color: $secondaryColor;
            }
            &.dark-bi {
              background: $darkThemelightCharcoal;
              color: $secondaryColor;
            }
          }

          .sidenav-title-sub-category {
            margin: 0px !important;
            font-size: 13px;
            font-weight: 400;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
            &.active {
              color: #fc007f !important;
            }
          }

          .sidenav-sub-category-icon {
            font-size: 18px;
            margin-right: 8px;
            &.dark-bi {
              color: $lightThemeWhiteBg;
            }
            &.light-bi {
              color: $primaryColor;
            }
            &.active {
              color: #fc007f !important;
            }
          }
        }
      }

      .nav-item {
        border: none !important;
        padding-left: 30px !important;

        .panorama-img-container {
          font-size: $appIconSize;
        }
        &.active,
        &:hover {
          border: none !important;
        }
      }
    }
  }
}

// .color-changing-div {
// }

// .color-changing-div:hover {
//   background-color: lightgreen;
// }

.active-row-hover {
  &:hover {
    border-radius: 3px;
    color: #fc007f;
    &.dark-bi {
      background: $darkThemelightCharcoal;
    }
    &.light-bi {
      background: $lightThemeWhiteBg;
    }
  }
}

.category-tree {
  border-left: 1px dashed #ccc8c8;
  margin-left: 6px;
}

.subcategory-tree {
  border-left: 1px dashed #ccc8c8;
  margin-left: 8px;
}
