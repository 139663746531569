@import "../../../../styles/sass/NewThemeVariables.scss";

.filter-new {
    width: 100%;
    display: flex;
    height: 42px;
    justify-content: space-between;
    margin-bottom: 25px;
    .input-search {
        width: 52%;
        border-radius: $primaryBR;
        //border: 1px solid $inputEleBorderColor;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // background-color: $inputSearchBGColor;
        // background-color: white;
        height: 24px;
        padding: 5px 10px;

        .input-label {
            width: 95%;
            font-family: "Energy";
            // background-color: white;
        }
        .search-icon {
            // color: $primaryColor;
            font-size: 15px;
        }
        .cancel-icon {
            // color: $primaryColor;
            font-size: 15px;
            opacity: 0;

            &.visible {
                opacity: 1;
            }
        }
    }
    .input-search .input-label {
        width: 100%;
        //background-color: #f7f7f7;
        //border-radius: 5px;
        float: left;
        height: 100%;
        border: 0px;
        // color: $primaryColor;
        //color: rgba(70, 90, 105, 0.5);
        // border-bottom: none !important;
        padding-left: 10px;
    }
    .right-filter-div {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .filter-btn {
            justify-content: flex-end;
            align-items: center;
            background: #282828 !important;
            border-radius: 5px;
            text-align: center;
            height: 32px;
            width: 100px;

            button {
                background: #282828 !important;
                color: #ffffff;
                display: flex;
                align-items: center;
                font-family: $fontFamily;
            }
            .filter-alt-icon {
                font-size: $iconSizeM;
                margin-left: 15px;
                border-left: 1px solid $appBGColor;
                padding-left: 10px;
            }
        }
    }

    .myconfig-switch-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 40;
        border-radius: 5px;
        background: rgba(70, 89, 106, 0.07);
        height: 32px;
        width: 100px;
        padding-right: 3px;
        margin-left: 10px;
        .myconfig-icons {
            cursor: pointer;
            color: $primaryColor;
            margin: 2px;
            width: 50px;
            display: flex;
            justify-content: center;
            font-size: $iconSizeM;

            &.active {
                height: 85%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                color: white;
                background-color: #282828;
                border-radius: 5px;
                padding: 2px;
                font-size: $iconSizeM;
            }
        }
    }
}

.filters-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    float: right;
    position: absolute;
    bottom: 0px;
    right: 0px;
    //width: 50%;
    .apply-filter-btn {
        background: #282828;
        border: 1px solid #282828;
        box-sizing: border-box;
        border-radius: 4px;
        margin-left: 10px;
        color: white;
        height: 30px;
        width: 100px;
        font-size: $primaryFontSizeXS;
    }
    .reset-filter-btn {
        margin-left: 10px;
        background: white;
        color: #282828;
        border: 1px solid #282828;
        box-sizing: border-box;
        border-radius: 4px;
        height: 30px;
        width: 100px;
        font-size: $primaryFontSizeXS;
    }
    button:hover {
        background: #282828;
        color: white;
    }
}

.filter-popover {
    box-shadow: 0px 0px 10px rgba(163, 163, 163, 0.25);
    border-radius: 10px;
    padding: 10px 12px;
    font-size: $primaryFontSizeS;
    .filter-popover-label {
        font-weight: bolder;
    }
    .no-selections {
        padding: 10px;
        text-align: center;
    }
}

.filters-title {
    //padding-left: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    color: $primaryColor;
}

.filters-container {
    //padding: $filtersPadding;
    // background-color: $appBGColor;
    //margin: $filtersMargin;
    border-radius: $primaryBR;
    font-size: $primaryFontSizeS;
    // border: 1px solid $inputEleBorderColor;

    .filters-title {
        margin: 0px;
        font-weight: 600;
        color: $primaryColor;
        font-size: $primaryFontSizeS;
        opacity: 0;
    }

    .reset-filter-icon {
        color: $primaryColor;
        font-size: $primaryFontSizeXS;
        font-family: "Energy";
    }
    .reset-filter-icon:hover {
        color: $primaryColor;
    }

    .filters {
        display: flex;
        justify-content: space-between;

        .filter-item {
            display: flex;
            flex-direction: column;
            height: $filterItemHeight;

            &.search {
                width: 55%;
            }

            &.country,
            &.brand,
            &.archetype {
                margin-left: 0px;
                width: 30%;
            }

            label {
                margin-bottom: 5px;
                // color: $primaryColor;
                font-weight: 600;
                font-size: $primaryFontSizeS;
                color: #eb367f;
            }

            select {
                // border-radius: 3px;
                // border: 1px solid $inputEleBorderColor;
                height: 45px;
                color: $primaryColor;
                font-family: "Energy";
                font-size: $primaryFontSizeXS;
                background: #ffffff;
                border: 1px solid #dedede;
                box-sizing: border-box;
                border-radius: 5px;
            }

            input {
                border: 0px;
                color: $primaryColor;
                font-size: $primaryFontSizeXS;
                height: 45px;
                color: $primaryColor;
                font-family: "Energy";
                font-size: $primaryFontSizeXS;
                background: #ffffff;
                // border: 1px solid #DEDEDE;
                box-sizing: border-box;
                border-radius: 5px;
            }

            .input-label {
                &::placeholder {
                    color: $primaryColor;
                    opacity: 0.5;
                }
            }

            .filter-text {
                display: flex;
                align-items: center;
            }
        }
    }
}
