@import "../../../styles/sass/NewThemeVariables.scss";

.analysis-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    // background: #f7f7f7;
    position: sticky;
    top: 0px;
    .default-scores {
        //   width: 50%;
        padding: 10px;
        display: flex;
        height: 20px;
        align-items: center;
        .circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            float: right;
            margin-left: 10px;
        }
        .vertical {
            border-left: 0.5px solid #282828;
            left: 50%;
            height: 20px;
        }
        span {
            padding: 0px 10px 0px 10px;
            font-size: $primaryFontSizeXS;
            // color: #000;
            font-family: "Energy";
        }
    }
    .date-filters {
        display: flex;
        //width: 40%;
        .sort-by-div {
            display: flex;
            align-items: center;
            p {
                font-size: 13px;
            }
        }
        .date-filter {
            width: 28%;
            margin-bottom: 10px;
            margin-left: 10px;
            float: right;
            select {
                height: 45px;
                margin-top: 10px;
                border: 2px solid black;
                border-radius: 5px;
            }
        }
    }
    .tweet-column {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        // .tweet-row {
        //   margin: 20px;
        //   min-width: 30%;
        //   flex: 0 0 29.333333%;
        // }
    }
}
.sentiment-container {
    //margin-top: 20px;
    // background: #f7f7f7;
    .no-tweets-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        padding: 20px 10px;
    }
    .loader {
        padding-bottom: 50px;
    }
    .no-tweets {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: bolder;
        //margin-top: 20px;
        img {
            height: 20vh;
            padding: 30px;
        }
    }
    .tweet-row {
        display: -ms-flexbox; /* IE 10 */
        display: flex;
        justify-content: space-evenly;
        -ms-flex-wrap: wrap; /* IE 10 */
        flex-wrap: wrap;
        padding: 0 4px;
        .tweets-col {
            -ms-flex: 18.33%;
            flex: 18%;
            max-width: 28.33%;
            padding: 10px;
            .tweet-comp {
                margin-bottom: 45px;
            }
            .score-row {
                // background: red;
                height: 20px;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                display: flex;
                padding: 12px 15px 8px 15px;
                color: #fff;
                justify-content: space-between;
                margin-top: -17px;
                align-items: center;
                span {
                    // padding: 10px;
                    font-size: 13px;
                }
            }
        }
    }
}
.dark-theme {
    color: #fff;
}

.light-theme {
    color: #000000;
}
