@import "../../../styles/sass/NewThemeVariables.scss";

// * {
//     box-sizing: border-box;
// }

.setup {
  &-section {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 65px;
    // padding-bottom: 25px;
    height: calc(100vh - 90px);
    overflow: auto;
    width: 100%;
    margin: 40px auto;
  }
  &-container {
    // background-color: #f6f6f6;
    // height: 100vh;
    height: calc(100vh - 25px);
    box-sizing: border-box !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  &-heading {
    // font-family: Energy;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    // color: #282828;
  }
  &-btn {
    // font-family: Energy;
    background: #fc007f;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    width: 274px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    &:disabled {
      opacity: 0.5;
    }
    &-update {
      background: transparent;
      color: #fc007f;
      // box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      width: 100px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1000;
      &:hover {
        border: 1px solid #fc007f;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  &-back-btn {
    &.MuiIconButton-root {
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 1000;
      color: #fc007f;
    }
  }
  &-geo-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    width: 211px;
    height: 38px;
    // color: #282828;
    border: 1px solid #d9dee1;
    &:hover {
      border: 1px solid #fd94cb;
    }
  }
  &-geo-btn-selected {
    color: #fd5bb2 !important;
    border: 1px solid #fd94cb !important;
  }
  &-title {
    font-family: "Energy";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 57px;
    color: #fc007f;
  }
  &-desc {
    font-family: "Energy";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    // color: #282828;
  }
  &-select-field {
    input {
      text-transform: none !important;
    }
  }
  &-search {
    align-items: center;
    width: 270px;
    border: 1px solid #00000033;
    & .MuiInputBase-root {
      font-size: 12px;
    }
    margin-top: -40px;
    div:first-of-type {
      width: 100%;
    }
    input {
      width: calc(100% - 80px);
    }
  }
}
.setup-card {
  border: 1px solid #d9dee1;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  // width: 143px;
  // height: 128px;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  cursor: pointer;
  &-function {
    border: 1px solid #d9dee1;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    // width: 70px;
    // height: 75px;
    width: 115px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1000;
    &-icon {
      height: 28px;
      left: 381px;
    }
    &-heading {
      line-height: 1.3 !important;
      text-align: center;
      padding: 3px;
      //   text-wrap: balance;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 18px;
      margin-top: 10px;
      //   white-space: nowrap;
    }
    &-subheading {
      font-style: normal;
      font-size: 7px;
      line-height: 16px;
      margin-top: -6px;
      white-space: nowrap;
    }
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 260px;
    }
    &-not-found {
      height: 100px;
    }
  }
  &:hover {
    border: 1px solid #fd94cb;
  }
  &-selected {
    color: #fd5bb2 !important;
    border: 1.5px solid #fd94cb !important;
  }
  &-container {
    border-radius: 15px;
    // width: 847px;
    width: 1300px;
    height: 630px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding: 30px 0px;
    position: relative;
    z-index: 10;
    box-sizing: border-box;

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 20;
      height: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      // max-height: 280px;
      // overflow-y: auto;
    }

    &-logo {
      width: 134px;
      height: 86px;
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &-job-container {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 625px;
        // width: 600px;
      }
    }
  }
  &-icon {
    height: 45px;
    left: 381px;
  }
  &-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    margin-top: 10px;
    white-space: nowrap;
  }
  &-subheading {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    white-space: nowrap;
  }
}

.dark {
  &-setup {
    &-container {
      background-color: #000000;
      color: #fff;
    }
    &-card-container {
      background-color: #000000;
      box-shadow: 0px 5px 15px #282828;
    }
    &-geo-btn {
      background-color: #282828;
      color: #fff;
    }
    &-card {
      background-color: #282828;
    }
  }
}

.light {
  &-setup {
    &-container {
      background-color: #f6f6f6;
      color: #282828;
    }
    &-card-container {
      background-color: #ffffff;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    }
    &-geo-btn {
      background-color: #ffffff;
      color: #282828;
    }
    &-card {
      background-color: #ffffff;
    }
  }
}
