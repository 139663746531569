@import "../../../styles/sass/NewThemeVariables.scss";

// colors
.pink {
  color: #fc007f;
}
.grey {
  color: #282828;
}

.register-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
}

.dark-subcategory-name {
  color: #fff;
}

.light-subcategory-name {
  color: #282828;
}
// .title {
//     font-style: normal;
//     font-weight: 600;
//     font-size: 20px;
//     line-height: 32px;
//     color: #282828;
//     white-space: nowrap;
// }

// .sub-title {
//     font-family: Energy;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 29px;
//     color: #282828;
//     margin: 10px 0;
// }

.heading {
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-family: Energy !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 18px !important;
  // color: #000 !important;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 5px !important;
  text-align: start;
  &.dark {
    color: #fff !important;
  }
  &.light {
    color: #000 !important;
  }
  span {
    color: red;
    margin-right: 3px;
  }
}
.sub-heading {
  font-family: Energy !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 23px !important;
  color: #282828 !important;
  margin-bottom: 5px;
  span {
    color: #fc007f;
    margin-right: 3px;
  }
}
.url {
  &-text {
    font-family: Energy;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
  }
  &-btn {
    background: transparent;
    border-bottom: 1px solid;
    padding: 0;
    height: auto;
    border-radius: 0;
    margin-bottom: 5px;
    color: #fc007f;
  }
}
// .btn {
//     &-chooseIcon {
//         background: #ffffff;
//         border: 1px solid #d0d6da;
//         border-radius: 5px;
//         width: 180px;
//         height: 35px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         color: #282828;
//         & .inner-content {
//             font-style: normal;
//             font-weight: 400;
//             font-size: 16px;
//             line-height: 19px;
//             margin-left: 10px;
//         }
//     }
// }

.checkBox-label {
  font-family: Energy !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #282828;
}
.Radio-labels {
  font-family: Energy;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 23px !important;
}
.desciption {
  // color: #415a6c;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-family: Energy;
  font-style: normal;
}
.inputField {
  font-family: Energy;
  background: rgba(230, 233, 238, 0.5);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  padding: 5px;
  border: none;
  width: 100%;
  box-sizing: border-box;
}
.urlBtn {
  font-family: Energy;
  background-color: #fbedf2 !important;
  border-radius: 3px;
  border-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #fc007f !important;
  text-transform: none !important;
}
.btn {
  font-family: Energy;
  background: #0058aa;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  width: 135px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &-status {
    & .MuiButton-root {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      text-transform: none;
      font-family: Energy;
    }
  }
  &-radio {
    & .MuiFormControlLabel-root {
      align-items: start;
      color: #282828;
    }
    & .Mui-disabled {
      & .Radio-labels {
        color: #8d9ca7;
      }
    }
    & .MuiRadio-root {
      padding-top: 0;
    }
  }
  &-radio-default {
    margin-right: 16px;
    margin-bottom: 5px;
    // margin-top: 5px;
    & .MuiFormControlLabel-root {
      color: #282828;
    }
    & .MuiRadio-root.Mui-checked {
      color: #0058aa;
    }
    & .Mui-disabled {
      cursor: not-allowed;
    }
    & .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
  &-track {
    font-family: Energy;
    color: #fc007f;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 2px solid #fc007f;
    border-radius: 0;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    &:hover {
      background-color: #fbedf2;
    }
  }
  &-add-access {
    margin-top: 25px !important;
    color: #0058aa;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    // line-height: 18px;
    letter-spacing: 0.1px;
    padding: 6px 25px;
    border-radius: 3px;
    border: 1px solid #0058aa;
    background: transparent;
  }
}
.loading-btn {
  color: #fc007f;
  border: 1px solid #fc007f;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.title-bottom-line {
  background-color: #fc007f;
  height: 4px;
  border-radius: 2px;
  width: 54px;
}

.form-container {
  // background: #FFFFFF;
  border: 1px solid #e5e5e5;
  // box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
  position: relative;
  // margin-left: 24px;
  // margin-top: 10px;
}
.form-container-red {
  border: 1px solid red !important;
}
.btn-cancel {
  background-color: #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  width: 50px;
}

.category-card {
  font-family: Energy;
  // border: 1px solid #e5e5e5;
  border-radius: 3px;
  border-left: 5px solid #fc007f !important;
  padding: 7px 12px;
  margin-top: 5px;
  color: #fc007f;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // span {
  //     color: #282828;
  // }
  .drag-icon {
    display: none;
    font-size: 20px;
  }
  &:hover {
    .drag-icon {
      display: inline-block;
      cursor: grab;
    }
  }
}

.sub-category-card {
  // border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-top: 5px;
  padding: 5px 12px;
  font-family: Energy;
  // color: #282828;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-name {
    font-size: 16px;
  }
  &-url {
    font-size: 10px;
  }
  .sub-categroy-card-url {
    font-size: 10px;
    margin-top: 3px;
    // word-break: break-word;
    a {
      // color: #282828;
      text-decoration: none;
    }
  }
  .drag-icon {
    display: none;
    font-size: 20px;
  }
  &:hover {
    .drag-icon {
      display: inline-block;
      cursor: grab;
    }
  }
}

.add-category-btn {
  font-family: Energy;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.choose-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  // color: #282828;
  &:hover {
    background-color: #fbedf2;
    border-radius: 3px;
  }
}
.selected {
  background-color: #fbedf2;
  border-radius: 3px;
  color: #282828 !important;
}
.validation_msg {
  font-family: Energy;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  margin-top: 3px;
  text-align: start;
}

.info-content {
  padding: 10px 20px;
}

.search-icons {
  align-items: center;
  width: 350px;
  border: 1px solid #00000033;
  div:first-of-type {
    width: 100%;
  }
  input {
    width: calc(100% - 80px);
  }
}

.crop-image {
  // width: 100%;
  height: 450px;
  width: 450px;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-button {
  width: 120px;
  margin-right: 24px;
}

.final-image {
  // max-width: 100%;
  // max-height: 100%;
  // min-width: 180px;
  // // min-height: 180px;
  max-width: 300px;
  max-height: 220px;
}
.icon-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.cancel-btn {
  background-color: transparent;
  color: #fc007f;
  border: 1px solid #fc007f;
  font-weight: 400;
  width: 135px;
}

// .stepper {
//     & .MuiStepLabel-root {
//         padding: 0;
//     }
//     & .MuiStepLabel-iconContainer {
//         padding-right: 20px;
//     }
//     & .MuiStepConnector-root {
//         margin-left: 4px;
//     }
//     & .MuiStepLabel-label {
//         font-family: Energy;
//         font-style: normal;
//         font-weight: normal;
//         font-size: 14px;
//         line-height: 23px;
//     }
// }

.container {
  // font-family: "Energy";
  // color: #282828;
  // text-align: center;
  // padding-top: 40px;
  // // background-color: #e5e5e5;
  // background-color: #f6f6f6;
  // min-height: calc(100vh - 116px);
  // // padding-bottom: 10px;
  // display: flex;
  // flex-direction: column;
  font-family: "Energy";
  min-height: calc(100vh - 116px);
  // background-color: #e5e5e5;
  // color: #282828;
  text-align: center;
  padding-top: 15px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: relative;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  // font-weight: 600;
  // font-size: 30px;
  line-height: 32px;
}
.description {
  // color: #415a6c;
  font-family: Energy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.1px;

  // font-size: 14px;
  // line-height: 23px;
}

.new-data-quote {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  &-dark {
    color: #fff;
  }
  &-light {
    color: #000;
  }

  &-underline {
    border-bottom: 1px solid #757575;
    min-width: 834px;
    max-width: 1144px;
    width: 83%;
    padding-bottom: 15px;
    position: relative;
    margin: 0px auto;
    text-align: center;
  }
}

.register-config-field {
  // max-width: 300px !important;
  flex: 1 !important;
  // @media screen and (max-width: 1000px) {
  //     width: 250px !important;
  // }
}
.register-config-dev-env {
  width: 180px !important;
  @media screen and (max-width: 1150px) {
    width: 150px !important;
  }
  @media screen and (max-width: 1060px) {
    width: 130px !important;
  }
}
.semi-circle {
  width: 90%;
  position: absolute;
  top: 100vh;
  transform: translateY(-100%);
}
// .stepper {
//   white-space: nowrap;
//   & .MuiStepLabel-alternativeLabel {
//     font-family: Energy;
//     font-weight: 600 !important;
//     // color: #282828 !important;
//   }
//   & .Mui-completed {
//     color: #fc007f !important;
//     font-weight: 600 !important;
//   }
//   & .Mui-active {
//     // color: #ea3592 !important;
//     // color: #282828 !important;
//     font-weight: 600 !important;
//   }
//   & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
//     margin-top: 5px;
//   }
//   &-default {
//     cursor: pointer;
//     white-space: nowrap;
//     & .MuiSvgIcon-root {
//       color: #fc007f !important;
//       cursor: pointer;
//     }
//     & .MuiStepLabel-alternativeLabel {
//       font-family: Energy;
//       font-weight: 600 !important;
//       color: #fc007f !important;
//       cursor: pointer;
//     }
//     & .Mui-completed {
//       color: #fc007f !important;
//       font-weight: 600 !important;
//       cursor: pointer;
//     }
//     & .Mui-active {
//       color: #fc007f !important;
//       font-weight: 600 !important;
//       cursor: pointer;
//     }
//     & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
//       margin-top: 5px;
//     }
//   }
// }

.card {
  // background: #fffbfd;
  box-shadow: 0px 5px 15px rgba(70, 90, 105, 0.08);
  border-radius: 3px;
  padding: 34px 40px;
  // width: 280px;
  height: 295px;
  margin-top: 20px;
  border: 1.5px solid transparent;
  cursor: pointer;
  position: relative;
  text-align: justify;
  flex: 1;
  max-width: 280px;
  // min-width: 225px;
  @media screen and (max-width: 1150px) {
    padding: 34px 30px;
  }
  &-disabled {
    div {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  &-selected {
    border: 1.5px solid #fc007f;
  }
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 50px;
  }
}

.btn {
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  // font-size: 14px;
  // line-height: 19px;
  font-size: 12px;
  line-height: 18px;
  background-color: #0058aa;
  color: #ffffff;
  &-next {
    // width: 200px;
    // height: 35px;
    width: 148px;
    height: 28px;
    border-radius: 3px;
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    border: 1px solid #0058aa;
  }
  &-back {
    background-color: transparent;
    color: #000000;
    // width: 200px;
    // height: 35px;
    // font-weight: 600;
    // width: 77px;
    width: 148px;
    height: 28px;
    font-weight: 400;
    font-style: normal;
    // font-size: 12px;
    // line-height: 18px;
    border-color: #000;
    position: relative;
    right: 25px;
    &-dark {
      border-color: #fff;
      color: #fff;
    }
    &-light {
      border-color: #000;
      color: #000000;
    }
  }
  &-status {
    border-radius: 3px;
    font-family: Energy;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    background-color: transparent;
    width: 130px;
    height: 30px;
    text-transform: capitalize;
    cursor: default;
  }
  &-approve {
    background: #55b82a;
    color: #fff;
  }
  &-pending {
    background: #ed8a2c;
    color: #fff;
  }
  &-rejected {
    background: red;
    color: #fff;
  }
  &-published {
    background: #fc007f;
    color: #fff;
  }
  &-chooseIcon {
    height: 24px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: unset;
    & .inner-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin-left: 10px;
    }
  }
  &-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-draft {
    background: #ed8a2c;
    color: #fff;
    font-style: normal;
    border: 1px solid #ed8a2c;
    // width: 200px;
    // height: 35px;
    // font-weight: 600;
    width: 148px;
    height: 28px;
    font-weight: 400;
    // font-size: 12px;
    // line-height: 18px;
    position: absolute;
    left: 20px;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  &-createADGroup {
    font-family: "Energy";
    font-weight: 400;
    font-size: 12px;
    background-color: transparent;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &-dark {
      border: 1px solid #fff;
      color: #fff;
      img {
        filter: brightness(100);
      }
    }
    &-light {
      border: 1px solid #0058aa;
      color: #0058aa;
    }
  }
}
.app-name {
  font-family: "Energy";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 49px;
  color: #fc007f;
}

.app-name-status {
  display: flex;
  gap: 25px;
  align-items: center;
}
.sub-title {
  font-family: "Energy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  // color: #282828;
}
.sub-category-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 24px;
  button {
    width: 50px;
  }
}
.shake-category-field {
  animation: moveFields 0.5s;
  animation-iteration-count: 2;
}

.input-placeholder-text {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #fc007f;
    opacity: 1;
  }
}
@keyframes moveFields {
  0% {
    transform: translateX(5px);
  }
  10% {
    transform: translateX(0px);
  }
  20% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(-5px);
  }
  70% {
    transform: translateX(0px);
  }
  80% {
    transform: translateX(5px);
  }
  90% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-5px);
  }
}

// .autoCompeteContainer {
//     input::-webkit-input-placeholder,
//     textarea::-webkit-input-placeholder {
//         // color: #282828;
//         color: #fff;
//         opacity: 1;
//     }

//     input:-moz-placeholder,
//     textarea:-moz-placeholder {
//         // color: #282828;
//         color: #fff;
//         opacity: 1;
//     }
// }
.error {
  & .MuiInputBase-input {
    border: 1px solid red !important;
  }
  &-dropdown {
    padding-top: 6px;
    font-size: 12px;
    text-align: start;
  }
  &-border {
    border-color: red !important;
  }
}

.coming-soon {
  background-color: #fc007f;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: -2px;
  bottom: 25px;
  padding: 5px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  opacity: 1 !important;
}
.register-footer-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sync {
  &-dark {
    color: #fff;
  }
  &-light {
    color: #000;
  }
  &-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
  }
  &-sub-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
  }
  &-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    margin-top: 10px;
  }
  &-access-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    background: transparent;
    color: #fc007f;
    div {
      font-weight: 600;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.dark {
  // background-color: #000000;
  &-color {
    color: #fff;
  }
  &-container {
    color: #fff;
  }
  &-card {
    background-color: #282828;
  }
  &-autoCompeteContainer {
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #fff;
      opacity: 1;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #fff;
      opacity: 1;
    }
  }
  &-btn {
    &-chooseIcon {
      color: #fff;
      // background-color: #282828;
      // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    }
    &-radio {
      & .MuiFormControlLabel-root {
        color: #fff;
      }
    }
    &-decline {
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
    }
  }
  &-stepper {
    & .Mui-active {
      color: #0058aa !important;
    }
    & .MuiStepLabel-label {
      font-family: Energy;
      font-size: 13px;
      font-weight: 400;
      color: #8b8a8a;
    }
    & .MuiStepLabel-label.Mui-active {
      color: #fff !important;
    }
    & .MuiStepLabel-alternativeLabel {
      color: #fff !important;
    }
    & .Mui-completed {
      color: #0058aa !important;
      font-weight: 400 !important;
    }
    & .MuiStepLabel-label.Mui-completed {
      color: #fff !important;
      font-weight: 400 !important;
    }
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }
  &-sub-category-card {
    border: 1px solid #e5e5e5;
    color: #fff;
    a {
      color: #fff;
    }
  }
  &-sub-categroy-card-url {
    a {
      color: #fff;
      font-size: 10px;
      text-decoration: none;
    }
  }
  &-category-card {
    border: 1px solid #e5e5e5;
    span {
      color: #fff;
    }
  }
  &-choose-icon {
    color: #fff;
    &:hover {
      color: #282828;
    }
  }
  &-placeholder {
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #fff;
      opacity: 1;
    }
  }
  &-color-theme {
    input {
      color: #fff;
    }
  }
}

.light {
  // background-color: #f6f6f6;
  &-color {
    color: #282828;
  }
  &-container {
    color: #282828;
  }
  &-card {
    background-color: #fffbfd;
  }
  &-autoCompeteContainer {
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #282828;
      opacity: 1;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #282828;
      opacity: 1;
    }
  }
  &-btn {
    &-chooseIcon {
      // background-color: #282828;
      // border: 1px solid #d0d6da;
      // background-color: #fff;
      color: #415a6c;
      // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    }
    &-radio {
      & .MuiFormControlLabel-root {
        // color: #282828;
        color: #415a6c;
      }
    }
    &-decline {
      border: 1px solid #282828;
      color: #282828;
      cursor: pointer;
    }
  }
  &-stepper {
    & .Mui-active {
      color: #000 !important;
    }
    & .MuiStepLabel-label {
      font-family: Energy;
      font-size: 13px;
      font-weight: 400;
      color: #0158aa99;
    }
    & .MuiStepLabel-alternativeLabel {
      color: #282828 !important;
    }
    & .Mui-completed {
      color: #0058aa !important;
      font-weight: 400 !important;
    }
    & .MuiStepLabel-label.Mui-completed {
      color: #0058aa !important;
      font-weight: 400 !important;
    }
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: #0158aa99;
    }
  }
  &-sub-category-card {
    color: #282828;
    border: 1px solid #8d9ca6;
    a {
      color: #282828;
    }
  }
  &-sub-categroy-card-url {
    a {
      color: #282828;
      font-size: 10px;
      text-decoration: none;
    }
  }
  &-category-card {
    border: 1px solid #8d9ca6;
    span {
      color: #282828;
    }
  }
  &-choose-icon {
    color: #282828;
  }
  &-placeholder {
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #282828;
      opacity: 1;
    }
  }
}

.accordion-heading {
  color: #000;
  font-family: Energy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  &.dark {
    color: #fff;
  }
  &.light {
    color: #000;
  }
}

.div-box {
  border-top: 1px solid #d9dee2;
  padding: 15px 0px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  display: flex;
}

.largeHeading {
  margin-left: 25px;
  margin-top: 22px;
  color: #000;
  font-family: Energy;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 81.818% */
  letter-spacing: 0.6px;
}

.menu {
  padding-top: 8px;
  padding-left: 8px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 10px;
}
.menu:hover {
  opacity: 0.8;
  background-color: #0058aa;
  color: white;
}

.heading-input {
  font-family: Energy;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 180% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
  padding-left: 1px;
  padding-bottom: 5px;
  .dark {
    color: #fff;
  }
  .light {
    color: #000;
  }
  span {
    color: #fc007f;
    margin-right: 3px;
  }
}

.track-status-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  font-family: Energy;
  font-style: normal;
  &-dark {
    color: #fff;
  }
  &-light {
    color: #4e4e4e;
  }
}

.track-status-url-text {
  color: #0058aa;
  font-family: Energy;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 30px;
}

.ml-Config-label {
  text-align: justify;
  padding-bottom: 5px;
  font-family: Energy;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.1px;
  .ml-Config-dark {
    color: #fff;
  }
  .ml-Config-light {
    color: #000;
  }
}

.ml-Config-value {
  margin-left: 15px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  .ml-Config-dark {
    color: #fff;
  }
  .ml-Config-light {
    color: #4e4e4e;
  }
}

.appType-description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.6px;
  text-align: start;
  padding-top: 15px;
  width: 100%;
  height: 205px;
  &-dark {
    color: #b1b1b1;
  }
  &-light {
    color: #4e4e4e;
  }
}

.appType-description-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-align: start;
  padding: 0px;
  min-width: 834px;
  max-width: 1144px;
  width: 83%;
  &-dark {
    color: #fff;
  }
  &-light {
    color: #000;
  }
}

.appTypeIcon-span {
  font-size: 80px !important;
  font-weight: 400;
}

.appDetailsIcon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px !important;
  width: 119px;
  height: 119px;
  border: 1px solid #8d9ca7;
  border-radius: 50%;
  margin: 25px;
}

.appDetailsIcon-heading {
  display: flex;
  align-items: center;
  height: 18px;
  font-family: Energy;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.10000000149011612px;
  margin-top: 6px;
  &-dark {
    color: #fff;
  }
  &-light {
    color: #0058aa;
  }
}

.appDetailsLabel-heading {
  font-family: Energy;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.10000000149011612px;
  text-align: start;
  text-transform: uppercase;
  padding-left: 1px;
  padding-bottom: 2px;
  .dark {
    color: #fff;
  }
  .light {
    color: #000;
  }
  span {
    color: red;
    margin-right: 3px;
  }
}

.apptype-card {
  align-content: center;
  height: 70px;
  border-radius: 3px;
  border: 1.5px solid transparent;
  cursor: pointer;
  position: relative;
  text-align: justify;
  flex: 1;
  color: var(--text-text-secondary, #4e4e4e);
  padding: 25px;
  min-width: 218px;
  max-width: 314.67px;
  width: 31%;
  background-color: #ffffff;
  &:hover {
    box-shadow: 2px 3px 10px 0px rgb(178, 189, 202);
  }

  &-disabled {
    div {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  &-selected {
    border: 1px solid var(--border-border-blue, #0058aa);
    color: var(--text-text-info, #0058aa);
    box-shadow: 2px 3px 10px 0px rgb(178, 189, 202);
  }
  &-title {
    text-align: center;
    font-family: Energy;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.6px;
    margin-top: 10px;
  }
  &-icon {
    align-items: center;
    text-align: center;
  }
  &-subtitle {
    color: var(--text-text-secondary, #4e4e4e);
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }
}

.button-add-access {
  color: #0058aa;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 6px 25px;
  border-radius: 3px;
  border: 1px solid #0058aa;
  background: transparent;
  position: relative;
  left: 15px;
}

.ml-config-div {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.access-list-table-div {
  color: #8d9ca7;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin-top: 10px;
  display: flex;
  margin-bottom: 5px;
}

.access-list-row-left {
  text-align: left;
  width: 50%;
  margin-right: 12px;
}

.access-list-row-right {
  text-align: left;
  width: 50%;
  margin-left: 12px;
}

.access-list-row {
  padding-left: 8px;
  display: flex;
  align-items: center;
  color: #9a9495;
  font-family: Energy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  border-radius: 6px;
  border: 1px solid #e7e9ee;
  text-align: left;
  width: 50%;
}

.drawer-box {
  overflow: auto;
  border-radius: 6px;
  height: calc(100vh - 116px);
  padding: 20px 17px 10px 17px;
}

.card-heading {
  display: flex;
  justify-content: space-between;
  //   width: 100%;
  padding: 7px 0px 10px 0;
}
.link-icon {
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
    font-size: 0.85rem;
    color: grey;
  }
  span:hover {
    color: #fc007f;
  }

  p {
    font-size: 12px;
  }
}

.report-description {
  overflow: hidden;
  font-size: 12px;
  text-align: justify;
  height: 64px;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.report9-description {
  overflow: hidden;
  font-size: 12px;
  text-align: justify;
  height: 60px;
  width: calc(100% - 35px);
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.appDetails-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 1399px) {
    height: calc(100vh - 375px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (min-width: 1400px) {
    width: 98%;
    flex-direction: row;
  }
  @media (min-width: 1700px) {
    width: 79%;
    flex-direction: row;
  }
}

.IconAndCheckbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 1399px) {
    justify-content: space-evenly;
    margin-bottom: 25px;
    align-items: center;
  }
  @media (min-width: 1400px) {
    position: relative;
    bottom: 50px;
    margin-left: 50px;
    gap: 50px;
    width: 32%;
    flex-direction: column;
  }
  @media (min-width: 1700px) {
    gap: 50px;
    width: 30%;
    flex-direction: column;
  }
}

.Checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 1399px) {
    justify-content: center;
  }
}

.Checkbox-container:empty {
  display: none;
}

.seperator-line {
  @media (max-width: 1399px) {
    border-bottom: 1px solid #757575;
    margin-bottom: 25px;
    width: 96%;
  }
  @media (min-width: 1400px) {
    display: none;
  }
}

.DetailFields-container {
  width: 100%;
  align-items: center;
  @media (min-width: 1400px) {
    width: 55%;
  }
  &-grid {
    row-gap: 16px;
    column-gap: 32px;
    @media (max-width: 1399px) {
      width: 112% !important;
      margin-left: 15px;
      row-gap: 8px;
    }
  }
}

.RegionBox-container {
  @media (max-width: 1399px) {
    width: 93% !important;
  }
}

.DescBox-container {
  @media (max-width: 1399px) {
    width: 94% !important;
  }
}

.confirmationText {
  letter-spacing: 0.1px;
  font-family: Energy !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 0px !important;
  text-align: start;
  &.dark {
    color: #fff !important;
  }
  &.light {
    color: #000 !important;
  }
}

.custom_placeholder {
  ::-webkit-input-placeholder {
    font-weight: 400 !important;
  }
  &.dark {
    ::-webkit-input-placeholder {
      color: #fff !important;
    }
  }
  &.light {
    ::-webkit-input-placeholder {
      color: #4e4e4e !important;
    }
  }
}

.icon-for-text-editor {
  color: #fc007f;
  margin-left: 10px !important;
  font-size: 18px !important;
}

.ranking_msg {
  font-family: Energy;
  font-weight: 600;
  font-size: 12px;
  margin: 5px auto;
  line-height: 24px;
}

.top10users_and_rank_container {
  display: flex;
  flex-direction: column;
  @media (min-width: 1920px) {
    flex-direction: row;
    gap: 16px;
  }
}

.ranking_container {
  @media (min-width: 1920px) {
    width: 50%;
  }
}

.heading_and_rank_filter_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Energy;
  font-weight: 400;
  @media (min-width: 1920px) {
    // gap: 20px;
    margin-top: 0px;
  }
}

.chipStyle {
  font-family: Energy;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #cdd3d9;
}

.img_rankmsg_container {
  display: flex;
  justify-content: center;
  gap: 10px;
  // @media (min-width: 1500px) {
  //   padding: 25px;
  // }
}

.spanAppName {
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  vertical-align: middle;
  .light {
    color: #4e4e4e;
  }
  .dark {
    color: #fff;
  }
}

.main_rankcomp_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (min-width: 1920px) {
    justify-content: space-between;
    height: 363px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-box-light {
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: #282828;
  width: 100%;
  border: 1px solid rgba(66, 89, 108, 0.25);
  padding: 10px 0px;
  // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
}

.input-box-dark {
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  width: 100%;
  border: 1px solid rgba(66, 89, 108, 0.25);
  padding: 10px 0px;
  // box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #282828;
}

.input-light {
  background: none;
  padding-left: 10px;
  width: 83%;
  font-size: 14px;
  border: none;
  color: #282828;
  cursor: pointer;
}

.input-dark {
  background: none;
  padding-left: 10px;
  width: 83%;
  font-size: 14px;
  border: none;
  color: #fff;
  font-family: "Energy";
  cursor: pointer;
}

// .selected-report-light {
//   color: #282828;
// }
// .selected-report-dark {
//   color: #fff;
// }

// .selectedOption-light {
//   color: #000 !important;
//   background-color: #ddf3fc;
// }
// .selectedOption-dark {
//   color: #000 !important;
//   background-color: #ddf3fc;
// }

// .heading {
//   letter-spacing: 0.1px;
//   text-transform: uppercase;
//   font-family: Energy !important;
//   font-style: normal !important;
//   font-weight: 400 !important;
//   font-size: 11px !important;
//   line-height: 18px !important;
//   // color: #000 !important;
//   margin-bottom: 0;
//   margin-top: 0px;
//   padding-bottom: 2px;
//   text-align: start;
// }
